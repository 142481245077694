
import { Component, Prop, Vue } from "vue-property-decorator";
import { ITab } from "../../modules/tabs/types/tabs.types";
import { ISearchSegment } from "../../modules/fly-search/types/fly-search.types";
import moment from "moment";

@Component({})
export default class TabQuery extends Vue {
  // isSolo prop
  @Prop({
    type: Boolean,
    default: false
  })
    isSolo: boolean;

  @Prop({
    type: Object,
    required: true
  })
    tab: ITab;

  get query(): any {
    return this.tab.query;
  }

  get tabSegments(): ISearchSegment[] {
    if (!this.query) {
      return [];
    }

    if (this.tab.productType === "REGULAR_FLIGHT") {
      return this.query._segments;
    } else {
      return this.query._query.segments;
    }
  }

  get title() {
    if (this.tab.productType === "CAR") {
      return "Car";
    }

    if (!this.query) {
      return this.isSolo ? "Search home" : "new";
    }

    if (this.tab.productType === "REGULAR_FLIGHT") {
      if (this.tabSegments.length > 2) {
        return `${this.tabSegments?.[0]?.fromAirport?.city?.name} - ${this.tabSegments?.at(-1)?.toAirport?.city?.name}`;
      }
      return `${this.tabSegments?.[0]?.fromAirport?.city?.name} - ${this.tabSegments?.[0]?.toAirport?.city?.name}`;
    } else {
      return `${this.tabSegments?.[0]?.toAirport?.city?.name}`;
    }
  }

  get tabDate() {
    if (!this.query) {
      return " ";
    }
    const departureDate = this.tabSegments?.[0]?.departureDate;
    const arrivalDate = this.tabSegments?.at(-1)?.departureDate;
    let dateStr = "";

    if (departureDate) {
      dateStr += moment(departureDate).format("DD.MM.YY");
    }

    if (arrivalDate) {
      dateStr += ` - ${moment(arrivalDate).format("DD.MM.YY")}`;
    }

    return dateStr;
  }
}

import { PaxComplectResource, PaxType } from "tl-api-doc/typescript-generator";
import { IProductDetailsPaxComplectClass } from "modules/product-result/data/product-result.types";

export default class ProductDetailsPaxComplect
implements IProductDetailsPaxComplectClass {
  constructor(private _paxComplect: PaxComplectResource) {
  }

  get content(): IProductDetailsPaxComplectClass["content"] {
    return this._paxComplect;
  }

  get paxList(): PaxType[] {
    const paxList: PaxType[] = [];
    this._paxComplect.price.paxPrices.forEach(paxPrice => {
      for (let i = 0; i < paxPrice.quantity; i++) {
        paxList.push(paxPrice.paxType);
      }
    });
    return paxList;
  }
}

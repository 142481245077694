

import Verte from "verte";
import "verte/dist/verte.css";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ROOT_MODULE from "modules/root/root.name";
import {
  Accessibility,
  colorsCustomFunctionsList,
  colorsFunctionsList,
  contentsFunctionsList,
  cursorFunctionsList,
  fontsFunctionsList
} from "common/accessibility/accessibility";
import { Prop } from "vue-property-decorator";
import Cert from "applications/desktop/accessibility/cert.vue";
// register component globally
Vue.component("Verte", Verte);

@Component({
  name: "AccessPopup",
  components: { Cert }
})
export default class AccessPopup extends Vue {
  @Getter(`${ROOT_MODULE}/isB2b`)
    isB2b: boolean;

  @Prop({ type: Accessibility, required: true }) startAccessibility: Accessibility;
  isCertOpen = false;

  @Getter(`${ROOT_MODULE}/isAccessibility`)
    isAccessibility: boolean;

  @Action(`${ROOT_MODULE}/toggleAccessibility`)
    toggleAccessibility: (status: boolean) => Promise<void>;

  colorsCustomFunctionsList = colorsCustomFunctionsList;
  colorsFunctionsList = colorsFunctionsList;
  fontsFunctionsList = fontsFunctionsList;
  cursorFunctionsList = cursorFunctionsList;
  contentsFunctionsList = contentsFunctionsList;

  resetAll() {
    this.startAccessibility.resetAll();
  }

  changeColor(type: "bg_color" | "text_color", value: string) {
    this.startAccessibility.changeColor(type, value);
  }

  toggleColorsCustomFunctionsList(type: keyof typeof colorsCustomFunctionsList) {
    this.startAccessibility.toggleClass(type);
  }

  toggleCcursorFunctionsList(type: keyof typeof cursorFunctionsList) {
    this.startAccessibility.toggleClass(type);
  }

  toggleColorsFunctionsList(type: keyof typeof colorsFunctionsList) {
    this.startAccessibility.toggleClass(type);
  }

  toggleFontsFunctionsList(type: keyof typeof fontsFunctionsList) {
    if (type === "fontSize") {
      this.startAccessibility.toggleFontSize();
    } else {
      this.startAccessibility.toggleClass(type);
    }
  }

  toggleContentsFunctionsList(type: keyof typeof contentsFunctionsList) {
    if (type === "focus_window") {
      this.startAccessibility.toggleWindow();
    }
    this.startAccessibility.toggleClass(type);
  }

  close() {
    this.$emit("close");
  }
}


import { Action, Getter } from "vuex-class";
import i18n from "../../../../../modules/i18n/i18n.name";
import LocaleSelector from "ui-modules/selectors/locale-selector.vue";
import { IFlyLocaleState } from "../../../../../modules/i18n/types/i18n.types";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "BuildingSearchLocaleSelector",
  components: {
    LocaleSelector
  }
})
export default class BuildingSearchLocaleSelector extends Vue {
  @Getter(`${i18n}/localeList`)
    locales: IFlyLocaleState["locales"];

  @Getter(`${i18n}/currentLocale`)
    currentLocale: string;

  @Action(`${i18n}/changeLocale`)
    changeLocale: (locale: string) => Promise<void>;
}

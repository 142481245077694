export enum colorsCustomFunctionsList {
    "bg_color" = "bg_color",
    "text_color" = "text_color"
}

export enum colorsFunctionsList {
    "monochrome" = "monochrome",
    "dark_contrast" = "dark_contrast",
    "bright_contrast" = "bright_contrast",
    "low_saturation" = "low_saturation",
    "height_saturation" = "height_saturation",
    "contrast" = "contrast"
}
export enum fontsFunctionsList {
    "fontSize" = "fontSize",
    "spacing" = "spacing"
}
export enum cursorFunctionsList {
    "cursorWhite" = "cursorWhite",
    "cursorBlack" = "cursorBlack"
}
export enum contentsFunctionsList {
    "zoom" = "zoom",
    "highlight_link" = "highlight_link",
    "highlight_headers" = "highlight_headers",
    "focus_window" = "focus_window"
}

export type allClass =
    keyof typeof cursorFunctionsList |
    keyof typeof colorsFunctionsList |
    keyof typeof contentsFunctionsList |
    keyof typeof fontsFunctionsList |
    keyof typeof colorsCustomFunctionsList;

export class Accessibility {
  bg_color = "#00ff00";
  text_color = "#000000";
  isWindow = false;
  fontSize:0|1|2|3 = 0;
  classList: Record<allClass, allClass|undefined> = {
    cursorBlack: undefined,
    cursorWhite: undefined,
    bg_color: undefined,
    fontSize: undefined,
    spacing: undefined,
    text_color: undefined,
    bright_contrast: undefined,
    contrast: undefined,
    dark_contrast: undefined,
    focus_window: undefined,
    height_saturation: undefined,
    highlight_headers: undefined,
    highlight_link: undefined,
    low_saturation: undefined,
    monochrome: undefined,
    zoom: undefined
  };

  resetAll() {
    (Object.keys(this.classList)as allClass[]).filter((key) => !!this.classList[key]).forEach((key) => this.disableClass(key));
  }

  toggleFontSize() {
    if (this.fontSize < 3) {
      this.fontSize++;
      this.enableClass("fontSize");
    } else {
      this.fontSize = 0;
      this.disableClass("fontSize");
    }

    switch (this.fontSize) {
    case 0:
      document.documentElement.style.setProperty("font-size", "medium");
      break;
    case 1:
      document.documentElement.style.setProperty("font-size", "large");
      break;
    case 2:
      document.documentElement.style.setProperty("font-size", "x-large");
      break;
    case 3:
      document.documentElement.style.setProperty("font-size", "xx-large");
      break;
    }
  }

  changeColor(type: "bg_color"| "text_color", value: string) {
    this[type] = value;
    document.documentElement.style.setProperty(`--${type}`, value);
  }

  toggleWindow() {
    this.isWindow = !this.isWindow;
    if (this.isWindow) {
      const focusWindowT = document.getElementById("focus-window-t");
      const focusWindowB = document.getElementById("focus-window-b");
      const onMouseMove = (e: MouseEvent) => {
        focusWindowT.style.height = e.pageY - 30 + "px";
        focusWindowB.style.top = e.pageY + 30 + "px";
      };
      document.addEventListener("mousemove", onMouseMove);
    }
  }

  disableClass(className: allClass) {
    if (document.body.classList.contains(className)) {
      document.body.classList.remove(className);
      this.classList[className] = undefined;
    }

    if (className === "focus_window") {
      this.isWindow = false;
    }
    if (className === "fontSize") {
      this.fontSize = 0;
      document.documentElement.style.setProperty("font-size", "medium");
    }
  }

  enableClass(className: allClass) {
    if (!document.body.classList.contains(className)) {
      document.body.classList.add(className);
      this.classList[className] = className;
    }
  }

  toggleClass(className: allClass) {
    if (document.body.classList.contains(className)) {
      document.body.classList.remove(className);
      this.classList[className] = undefined;
    } else {
      document.body.classList.add(className);
      this.classList[className] = className;
    }
  }
}

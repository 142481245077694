import { AxiosPromise } from "axios";
import {
  OrderItemResource,
  PaxComplectsResource,
  PaxSlotResource,
  PaxType,
  ProductType,
  TabSessionCreate,
  TabSessionFilter,
  TabSessionResource,
  TabSessionUpdate,
  TravelOrderResource
} from "tl-api-doc/typescript-generator";
import { ApiBaseResource } from "../../../common/types/types";
import { ICustomer } from "../../fly-order/types/fly-order.types";
import _ from "lodash";
import { IPackageProductPrice } from "../../product-result/data/package-result/package-result.types";
import { ICustomerHotel } from "../../product-order/data/product-order.types";

/// api type

// get tabs resource

export interface getTabSessionsPayload extends Partial<TabSessionFilter> {
    uuid: string;
}

export type getTabSessionsReturn = AxiosPromise<ApiBaseResource<TabSessionResource>>
export type getTabSessionsHandler = (payload: getTabSessionsPayload) => getTabSessionsReturn

// create tabs resource
export interface createTabSessionsPayload extends Partial<TabSessionCreate> {
    uuid: string;
}

export type createTabSessionsReturn = AxiosPromise<TabSessionResource>
export type createTabSessionsHandler = (payload: createTabSessionsPayload) => createTabSessionsReturn

// update tabs resource
export interface updateTabSessionsPayload extends Partial<TabSessionUpdate> {
    uuid: string;
}

export type updateTabSessionsReturn = AxiosPromise<TabSessionResource>
export type updateTabSessionsHandler = (tabSessionId: string, payload: updateTabSessionsPayload) => updateTabSessionsReturn

// add flight product to tabs resource
export interface addFlightProductToTabSessionsPayload {
    uuid: string,
    productId: string
}

export type addFlightProductToTabSessionsReturn = AxiosPromise<TabSessionResource>
export type addFlightProductToTabSessionsHandler = (tabSessionId: string, payload: addFlightProductToTabSessionsPayload) => addFlightProductToTabSessionsReturn

// add hotel product to tabs resource
export interface addHotelProductToTabSessionsPayload {
    uuid: string,
    productId: string
}

export type addHotelProductToTabSessionsReturn = AxiosPromise<TabSessionResource>
export type addHotelProductToTabSessionsHandler = (tabSessionId: string, payload: addHotelProductToTabSessionsPayload) => addHotelProductToTabSessionsReturn

// add Stat Package product to tabs resource

export interface addStatPackageProductToTabSessionsPayload {
    uuid: string,
    productId: string
}

export type addStatPackageProductToTabSessionsReturn = AxiosPromise<TabSessionResource>
export type addStatPackageProductToTabSessionsHandler = (tabSessionId: string, payload: addStatPackageProductToTabSessionsPayload) => addStatPackageProductToTabSessionsReturn

// closeTab

export interface deleteTabSessionsPayload {
    uuid: string;
    tabSessionId: string;
}

export type deleteTabSessionsReturn = AxiosPromise<void>
export type deleteTabSessionsHandler = (tabSessionId: string, payload: deleteTabSessionsPayload) => deleteTabSessionsReturn

/// api type end

// customer raw type

export interface CustomerRaw {
    name: string,
    surname: string,
    email: string,
    phone: string,
    birthDate: string,
    gender: string,
    passportNumber: string,
    passportExpiryDate: string,
    citizenship: string
}

// type birthDateValidationRules

export interface BirthDateValidationRules {
    "paxType": PaxType,
    "rangeOfBirthdays": {
        "INFANT": {
            "min": string// "2021-10-30",
            "max": string // "2023-09-28",
            "type": string // "java.time.LocalDate"
        },
        "CHILD": {
            "min": string // "2011-10-30",
            "max": string // "2021-10-29",
            "type": string // "java.time.LocalDate"
        },
        "ADULT": {
            "min": string // "1893-10-30",
            "max": string // "2011-10-29",
            "type": string // "java.time.LocalDate"
        },
        "SENIOR": {
            "min": string // "1893-10-30",
            "max": string // "1956-10-29",
            "type": string // "java.time.LocalDate"
        }
    },
    "mainBirthday": string // "2009-10-26"
}

// tab type
export interface ITab {
    requestId: string,
    query: any,

    searchPoolingId: NodeJS.Timeout,
    productType: ProductType,
    orderId: string,
    order: TravelOrderResource,

    activePath: string,

    // TODO BAD CODE ITEMS ONLY FOR HOTEL AND PACKAGE IN BIG STRUCTURE
    packageOrderItemIds: { [complectId: string]: string[] },

    orderItemIds: string[], // for flight only 1 item for other products more than 1
    orderItems: OrderItemResource[],

    preReservationId: string,
    preReservationPoolingId: NodeJS.Timeout,
    orderPreReservationStatus: string,

    reservationId: string,
    reservationPoolingId: NodeJS.Timeout,
    confirmationId: string,
    confirmationPoolingId: NodeJS.Timeout,

    paymentCount: number,

    birthDateValidationRules: BirthDateValidationRules,

    productId: string[],
    // priceId: string[],

    customers: Array<ICustomer | ICustomerHotel>,
    customerIds: string[]
    owner: ICustomer,

    paxSlots: Partial<PaxSlotResource>[],
    tabIndex: number,
    isOwnerRegistered: boolean,
    passPayment: boolean,

    rawCustomers: CustomerRaw[],
    rawProductData: any,
    rawProduct: any,
    rawProductPrice: any,
    agency: any
    agencyId: string
    additions: any,

    selectedPaxComplects: any,
    paxComplects: PaxComplectsResource[],
    services: any[],

    searchFilter: any,

    tabSessionId: string,
    tabSessionResource?: TabSessionResource,

    tabError?: any
    TabErrorText?: string

    savedSeats: any
    savedServices: any
    isUseClientContactsData: boolean

    isExpired: boolean
}

// empty tab const

export const emptyTab: ITab = {
  packageOrderItemIds: {},
  isOwnerRegistered: false,
  searchFilter: null,
  additions: null,
  tabIndex: 0,
  savedSeats: null,
  savedServices: null,
  activePath: "/",
  query: null,
  birthDateValidationRules: null,
  productType: null,
  confirmationId: "",
  confirmationPoolingId: null,
  customers: [],
  customerIds: [],
  orderId: "",
  order: null,
  orderItemIds: [],
  orderItems: [],
  owner: null,
  paxComplects: [],
  paxSlots: [],
  paymentCount: 0,
  preReservationId: "",
  preReservationPoolingId: null,
  orderPreReservationStatus: null,
  productId: [],
  rawCustomers: [],
  selectedPaxComplects: null,
  passPayment: false,
  rawProduct: null,
  rawProductData: null,
  rawProductPrice: null,
  agency: null,
  reservationId: "",
  reservationPoolingId: null,
  searchPoolingId: null,
  services: [],
  tabSessionId: "",
  requestId: "",
  tabError: null,
  TabErrorText: "",
  isUseClientContactsData: false,
  agencyId: null,
  isExpired: false
};

export const emptyTabFactory = (): ITab => {
  return _.cloneDeep(emptyTab);
};

/// store type

export interface ITabsState {
    tabs: ITab[],
    rawTabs: TabSessionResource[],
    activeTabIndex: number,
    selectedTabId: string
}

// store action type

// createTab

export interface createTabActionPayload {
    uuid: string;
    rawData?: Partial<ITab>;
}

export type createTabActionReturn = Promise<TabSessionResource>
export type createTabActionHandler = (payload: createTabActionPayload) => createTabActionReturn

// updateTab

export interface updateTabSessionsActionPayload extends Partial<ITab> {
    uuid: string;
}

export type updateTabSessionsActionReturn = Promise<TabSessionResource>
export type updateTabSessionsActionHandler = (tabSessionId: string, payload: updateTabSessionsActionPayload) => updateTabSessionsActionReturn

// closeTab

export interface closeTabSessionsActionPayload {
    uuid: string;
    tabSessionId: string;
}

export type closeTabSessionsActionReturn = Promise<void>
export type closeTabSessionsActionHandler = (payload: closeTabSessionsActionPayload) => closeTabSessionsActionReturn

// closeAllTabs

export interface closeAllTabsSessionsActionPayload {
    uuid: string;
}

export type closeAllTabsSessionsActionReturn = Promise<void>;
export type closeAllTabsSessionsActionHandler = (payload: closeAllTabsSessionsActionPayload) => closeAllTabsSessionsActionReturn;

// reloadTabsOnLogin

export interface reloadTabsOnLoginActionPayload {
    uuid: string;
    isNeedBlockSelectTab?: boolean;
}

export type reloadTabsOnLoginActionReturn = Promise<ITab[]>
export type reloadTabsOnLoginActionHandler = (payload: reloadTabsOnLoginActionPayload) => reloadTabsOnLoginActionReturn

// selectActiveTab

export interface selectActiveTabActionPayload {
    tabSessionId: string,
    fromTab?: ITab,
    uuid: string
}

export type selectActiveTabActionReturn = Promise<void>
export type selectActiveTabActionHandler = (payload: selectActiveTabActionPayload) => selectActiveTabActionReturn

// selectActiveTab

export interface openNewTabActionPayload {
    uuid: string,
    fromTab?: ITab
}

export type openNewTabActionReturn = Promise<void>
export type openNewTabActionHandler = (payload: openNewTabActionPayload) => openNewTabActionReturn

// onStartSearch

export interface onStartSearchActionPayload {
    requestId: string,
    query: any,
    productType: ProductType,
    uuid: string,
    tabSessionId: string,
}

export type onStartSearchActionReturn = Promise<void>
export type onStartSearchActionHandler = (payload: onStartSearchActionPayload) => onStartSearchActionReturn

// onCarStartTab

export interface onCarStartTabActionPayload {
    uuid: string,
    tabSessionId: string,
}

export type onCarStartTabActionReturn = Promise<void>
export type onCarStartTabActionHandler = (payload: onCarStartTabActionPayload) => onCarStartTabActionReturn

// goToTabActivePath
export interface goToTabActivePathActionPayload {
    tabSessionId: string,
}

export type goToTabActivePathActionReturn = Promise<void>
export type goToTabActivePathActionHandler = (payload: goToTabActivePathActionPayload) => goToTabActivePathActionReturn

// onAnyTabDataUpdate

export interface onAnyTabDataUpdateActionPayload extends Partial<ITab> {
    selectedTab?: Partial<ITab>,
    uuid: string,
    tabSessionId: string,
}

export type onAnyTabDataUpdateActionReturn = Promise<void>
export type onAnyTabDataUpdateActionHandler = (payload: onAnyTabDataUpdateActionPayload) => onAnyTabDataUpdateActionReturn

// onStartFlyBook

export interface onStartFlyBookActionPayload {
    // productRaw: FlightSearchItemResource;
    // productPrice: AgencyPriceResource;

    productId: string[],
    orderItemId: string[];
    orderItems: OrderItemResource[];
    orderId: string;
    uuid: string,
    tabSessionId: string,
}

export type onStartFlyBookActionReturn = Promise<void>
export type onStartFlyBookActionHandler = (payload: onStartFlyBookActionPayload) => onStartFlyBookActionReturn

// onStartStatPackageBook

export interface onStartStatPackageBookActionPayload {
    orderId: string;
    uuid: string,
    tabSessionId: string,

    agency: IPackageProductPrice,
    agencyId: string
    // TODO write payload

    productId: string[],
}

export type onStartStatPackageBookActionReturn = Promise<void>
export type onStartStatPackageBookActionHandler = (payload: onStartStatPackageBookActionPayload) => onStartStatPackageBookActionReturn

// onStartHotelBook

export interface onStartHotelBookActionPayload {
    orderId: string;
    uuid: string,
    tabSessionId: string,
    agency: any,
    agencyId: string
    // TODO write payload

    productId: string[],

}

export type onStartHotelBookActionReturn = Promise<void>
export type onStartHotelBookActionHandler = (payload: onStartHotelBookActionPayload) => onStartHotelBookActionReturn

// onTabError

export interface onTabErrorActionPayload {
    tabSessionId: string,
    error: any
}

export type onTabErrorActionReturn = Promise<void>
export type onTabErrorActionHandler = (payload: onTabErrorActionPayload) => onTabErrorActionReturn

// setTabExpired

export interface setTabExpiredActionPayload {
    tabSessionId: string;
}

export type setTabExpiredActionReturn = Promise<void>
export type setTabExpiredActionHandler = (payload: setTabExpiredActionPayload) => setTabExpiredActionReturn

// setTabUnExpired

export interface setTabUnExpiredActionPayload {
    tabSessionId: string;
}

export type setTabUnExpiredActionReturn = Promise<void>
export type setTabUnExpiredActionHandler = (payload: setTabUnExpiredActionPayload) => setTabUnExpiredActionReturn

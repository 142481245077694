import { ActionContext, Module } from "vuex";

import L, { Map } from "leaflet";
import { HotelSupplierPriceResource } from "tl-api-doc/typescript-generator";
import { RootState } from "../../applications/desktop/store";

export interface IMarkers {
    hotel: HotelSupplierPriceResource,
    coordinates: [number, number],
    price: string,
    marker: L.Marker,
    openMarker?: () => void,
    closeMarker?: () => void,
    clusterMarker: L.Marker
}

export interface MapState {
    isMapShow: boolean,
    map: Map,
    markers: IMarkers[]
}

const SET_MAP = "SET_MAP";
const RESET_MAP = "RESET_MAP";
const SET_MARKERS = "SET_MARKERS";
const SET_MAP_SHOW = "SET_MAP_SHOW";

export default (): Module<MapState, RootState> => ({
  namespaced: true,
  state: {
    isMapShow: true,
    map: null,
    markers: []
  },
  getters: {
    map: (state: MapState) => state.map,
    isMapShow: (state: MapState) => state.isMapShow,
    markers: (state: MapState) => state.markers
  },
  actions: {
    async showMap(context: ActionContext<MapState, RootState>) {
      context.commit(SET_MAP_SHOW, true);
    },
    async hideMap(context: ActionContext<MapState, RootState>) {
      context.commit(SET_MAP_SHOW, false);
    },
    async openMapMarkerOnClick(context: ActionContext<MapState, RootState>, marker: IMarkers) {
      if (marker && marker.openMarker && context.state.map) {
        context.state.map.fitBounds([marker.coordinates], { maxZoom: 17 });
        marker.openMarker();
      }
    },
    async openMapMarker(context: ActionContext<MapState, RootState>, marker: IMarkers) {
      if (marker && marker.openMarker && context.state.map &&
                context.state.map.getBounds().contains(marker.marker.getLatLng())) {
        marker.openMarker();
      }
    },
    async closeMapMarker(context: ActionContext<MapState, RootState>, marker: IMarkers) {
      if (marker && marker.closeMarker) {
        marker.closeMarker();
      }
    },
    async zoomToHotel(context: ActionContext<MapState, RootState>, hotelCoordinate: [number, number]) {
      if (context.state.map) {
        context.state.map.fitBounds([hotelCoordinate], { maxZoom: 17 });
      }
    },
    async applyMap(context: ActionContext<MapState, RootState>, map: Map) {
      context.commit(SET_MAP, map);
    },
    async clearMap(context: ActionContext<MapState, RootState>) {
      context.commit(RESET_MAP);
    },
    async setMarkers(context: ActionContext<MapState, RootState>, markers) {
      context.commit(SET_MARKERS, markers);
    }
  },

  mutations: {
    [SET_MAP_SHOW](state, isMapShow: boolean) {
      state.isMapShow = isMapShow;
    },
    [SET_MAP](state, map: Map) {
      state.map = map;
    },
    [RESET_MAP](state) {
      state.map = null;
    },
    [SET_MARKERS](state, markers) {
      state.markers = markers;
    }
  }
});


import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";

import { IRootState } from "modules/root/data/root.types";

import i18n from "modules/i18n/i18n.name";
import ROOT_MODULE from "modules/root/root.name";

import ResultOrdersTabs from "ui-modules/terminal-search/result-search.orders-tabs.vue";
import RootError from "./popups/RootError.vue";

@Component({
  components: {
    RootError,
    ResultOrdersTabs
  }
})
export default class DesktopApp extends Vue {
  @Getter(`${i18n}/currentLocale`)
    currentLocale: string;

  @Getter(`${ROOT_MODULE}/isTerminalSearch`)
    isTerminalSearch!: boolean;

  @Getter(`${ROOT_MODULE}/isB2b2c`)
    isB2b2c!: boolean;

  @Getter(`${ROOT_MODULE}/isB2b`)
    isB2b!: boolean;

  @Getter(`${ROOT_MODULE}/isErrorDialog`)
    isErrorDialog!: boolean;

  @Getter(`${ROOT_MODULE}/site`)
    site: IRootState["site"];

  get isPaymentPage(): boolean {
    const paymentRouteNames = [
      "successPayment",
      "errorPayment",
      "cancelPayment"
    ];
    return paymentRouteNames.includes(this.$route.name);
  }

  get isRtl(): boolean {
    return this.currentLocale === "he";
  }

  get ownAddress(): string {
    const address = this.site?.ownerCompany?.addresses;
    if (!address || address.length === 0) {
      return "";
    }

    return `${address[0].city ?? ""} ${address[0].street ?? ""} ${address[0].house ?? ""}`;
  }

  created() {
    const inputs = document.getElementsByTagName("input");
    const inputArr = Array.from(inputs);
    inputArr.forEach(input => {
      input.autocomplete = "off";
    });
  }

  showTermsOfUse() {
    if (this.site.termsOfUsePath) {
      window.open(this.site.termsOfUsePath, "_blank");
      return;
    }
    const routeData = this.$router.resolve("/terms-of-use");
    window.open(routeData.href, "_blank");
  }
}

import { IPaxes, IPaxesMaxRangeHotel, SearchingData } from "./hotel-app.types";

export default class HotelDesktopConfig extends SearchingData {
  readonly dateFormat: string = "dd-MM-yyyy";
  readonly rollbackLocale: string = "EN";
  readonly sorts: string[] = [
    "PRICE",
    "STOP",
    "OUT_ARRIVAL",
    "IN_ARRIVAL",
    "OUT_DEPARTURE",
    "IN_DEPARTURE",
    "CONNECTION"
  ];

  readonly pax: IPaxesMaxRangeHotel = {
    ADULT: [0, 9],
    CHILD: [0, 5]
  };

  constructor() {
    super();
  }
}


import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Popup from "../../../ui-modules/popups/popup.vue";
import PaymentName from "../../../modules/payment/payment.name";
import ROOT_MODULE from "../../../modules/root/root.name";

@Component({
  components: { Popup }
})
export default class PaymentBlockHelperPopUp extends Vue {
  @Getter(`${PaymentName}/isBadOpenPaymentUrlModal`)
    isBadOpenPaymentUrlModal: boolean;

  @Getter(`${PaymentName}/paymentUrl`)
    paymentUrl?: string;

  @Getter(`${ROOT_MODULE}/isB2b`)
    isB2b: boolean;

  @Action(`${PaymentName}/closeBadPaymentUrlModal`)
    closeBadPaymentUrlModal: () => void;

  get className(): string {
    return this.isB2b ? "payment-popup isb2b" : "payment-popup";
  }

  closeModal(): void {
    this.closeBadPaymentUrlModal();
  }

  goToPaymentAndClose(): void {
    window.open(this.paymentUrl, "_blank");
    this.closeModal();
  }
}

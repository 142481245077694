import { IPaxes, SearchingData } from "./package-app.types";

export default class PackageDesktopConfig extends SearchingData {
  readonly dateFormat: string = "dd-MM-yyyy";
  readonly rollbackLocale: string = "EN";
  readonly sorts: string[] = [
    "PRICE",
    "STOP",
    "OUT_ARRIVAL",
    "IN_ARRIVAL",
    "OUT_DEPARTURE",
    "IN_DEPARTURE",
    "CONNECTION"
  ];

  constructor() {
    super();
    delete this.pax.SENIOR;
    delete this.pax.INFANT;
  }
}

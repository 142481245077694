
import html2canvas from "html2canvas";
import { Component, Vue } from "vue-property-decorator";
import { axiosLogs, clearAxiosLogs, getPrintBaseUrl } from "../../../common/http/createHttp";
import { sendBugReport } from "./BugReportSender";
import { IBugReportPayload } from "./types";
import { Getter } from "vuex-class";
import ROOT_MODULE from "../../../modules/root/root.name";
import DotsLoading from "../../../ui-modules/dots-loading/index.vue";
import { ICompany, ICompanyUser } from "../../../modules/root/data/root.types";

@Component({
  components: { DotsLoading }
})
export default class BugReportAutomationBtn extends Vue {
  @Getter(`${ROOT_MODULE}/uuid`)
    uuid: string;

  @Getter(`${ROOT_MODULE}/company`)
    company: ICompany;

  @Getter(`${ROOT_MODULE}/ownUser`)
    ownUser: ICompanyUser;

  @Getter(`${ROOT_MODULE}/isB2b2c`)
    isB2b2c: boolean;

  isModalVisible = false;
  textarea = "";
  screenshot: string = null; // base64 string
  isSendInProgress = false;
  isGenerateInProgress = false;

  get isAccountBlocked(): boolean {
    return this.company?.status === "BLOCKED";
  }

  get subject(): string {
    let subject = this.$t("bugReport.emailSubject") as string;

    if (this.company && this.ownUser) {
      subject += ` ${this.company.name} (${this.ownUser.firstName} ${this.ownUser.lastName})`;
    }
    return subject;
  }

  get proxyPath(): string {
    return getPrintBaseUrl() + "/pdf/cors/";
  }

  // Проверка, есть ли информация в переменных, если нет, то -
  get ownUserPhone(): string {
    return this.ownUser?.phone ?? "-";
  }

  get ownUserEmail(): string {
    return this.ownUser?.email ?? "-";
  }

  get midOfficeName(): string {
    return this.company?.parentCompany?.name ?? "-";
  }

  created() {
    this.textarea = "";
  }

  async openModal() {
    this.$toasted.show(this.$t("bugReport.generating") as string, {
      type: "info",
      duration: 15000
    });
    this.isGenerateInProgress = true;
    this.screenshot = await this.captureScreen();
    this.$toasted.clear();
    this.isModalVisible = true;
    this.textarea = "";
    this.isGenerateInProgress = false;
  }

  /**
   * @return {Promise<string>} - base64 string
   */
  async captureScreen(): Promise<string> {
    const screenshotElement = async(element: HTMLElement) => {
      const canvas = await html2canvas(element, {
        proxy: this.proxyPath,
        // useCORS: true,
        allowTaint: true,
        scale: 2, // увеличиваем масштаб для улучшения качества

        ignoreElements: (el) => el.classList.contains("hide-from-html2canvas")
      });
      return canvas.toDataURL(); // вернуть данные в формате data URL
    };

    // Использование:
    const element: HTMLElement = document.querySelector(".app");
    return screenshotElement(element);
  }

  async sendBugReport() {
    this.isSendInProgress = true;
    // reportData all data for send to server
    const reportData: IBugReportPayload = {
      subject: this.subject,
      text: `\nSender information:\n\n  email: ${this.ownUserEmail}\n  phone: ${this.ownUserPhone}\n  mid-office: ${this.midOfficeName}\n\nReport message:\n\n  ` +
          this.textarea,
      xhrLogs: axiosLogs,
      screenshot: this.screenshot,
      uuid: this.uuid
    };
    try {
      await sendBugReport(reportData);
      this.$toasted.show(this.$t("bugReport.success") as string, {
        type: "success",
        duration: 5000
      });

      clearAxiosLogs();
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      this.$toasted.show(this.$t("bugReport.error") as string, {
        type: "error",
        duration: 5000
      });
      return Promise.reject(e);
    } finally {
      this.isSendInProgress = false;
      this.isModalVisible = false;
      this.textarea = "";
    }
  }

  cancel() {
    this.isModalVisible = false;
  }
}

import {
  IAdditional,
  IGoogleGeo,
  IPackageHotel,
  IPackageHotelClass,
  IRemark
} from "modules/product-result/data/product-result.types";

export default class PackageHotel implements IPackageHotelClass {
  constructor(private _hotel: IPackageHotel) {
  }

  get content(): IPackageHotelClass["content"] {
    return this._hotel;
  }

  get name(): string {
    return this._hotel.name;
  }

  get description(): string {
    return this._hotel.description || null;
  }

  get facilities(): any {
    return this._hotel.facilities;
  }

  get stars(): number {
    return this._hotel.stars;
  }

  get address(): string {
    return (this._hotel.address && this._hotel.address.street) || null;
  }

  get geo(): IGoogleGeo {
    return this._hotel.geoLocation &&
    this._hotel.geoLocation.y &&
    this._hotel.geoLocation.x
      ? {
        lat: this._hotel.geoLocation.y,
        lng: this._hotel.geoLocation.x
      }
      : null;
  }

  get remarks(): IRemark[] {
    return this._hotel.remarks;
  }

  getImages(): Array<{
    url: string
    title: string
    type: string
  }> {
    return this._hotel.images.map((image: IAdditional) => {
      return {
        title: image.title,
        type: image.type,
        url: image.url
      };
    });
  }

  getMainImages(): Array<{
    url: string
    title: string
  }> {
    return this._hotel.images.filter((image: IAdditional) => image.type === "MAIN");
  }

  getMainImage(): string {
    if (this._hotel.images.length) {
      const mainImage = this._hotel.images.find((image: IAdditional) => image.type === "MAIN");
      return mainImage ? mainImage.url : this._hotel.images[0].url;
    }
    return null;
  }
}

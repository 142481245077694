import { Store, StoreOptions } from "vuex";

import ROOT_MODULE from "modules/root/root.name";
import { rootStore } from "modules/root/root.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(ROOT_MODULE, rootStore);
};

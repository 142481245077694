import { Store, StoreOptions } from "vuex";

import FLY_RESULT from "./fly-result.name";
import { flyResultStore } from "./fly-result.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(FLY_RESULT, flyResultStore);
};

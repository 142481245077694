import FlyLocation from "modules/fly-location/fly-location.entry";
import FlyOrder from "modules/fly-order/fly-order.entry";
import FlyResult from "modules/fly-result/fly-result.entry";
import FlySearch from "modules/fly-search/fly-search.entry";
import PostMessage from "modules/post-message/post-message.entry";
import Payment from "modules/payment/payment.entry";
import Tabs from "modules/tabs/tabs.entry";
import i18nEntry from "modules/i18n/i18n.entry";
import rootEntry from "modules/root/root.entry";
import mapModule from "modules/map/map.entry";

import ProductOrder from "modules/product-order/product-order.entry";
import ProductResult from "modules/product-result/product-result.entry";
import ProductSearch from "modules/product-search/product-search.entry";

import HotelLocation from "modules/hotel-location/hotel-location.entry";
import DynamicPackage from "../../modules/dynamic-package/dynamic-package.entry";

import TL_DESKTOP from "applications/desktop/flight-app/desktop-app.name";
import PACKAGE_DESKTOP from "applications/desktop/package-app/package-app.name";
import HOTEL_DESKTOP from "applications/desktop/hotel-app/hotel-app.name";

import NotificationEntry from "../../modules/notification/notification.entry";

import { desktopStore } from "applications/desktop/flight-app/desktop-app.store";
import { packageStore } from "applications/desktop/package-app/package-app.store";
import { hotelStore } from "applications/desktop/hotel-app/hotel-app.store";
import { Store } from "vuex";
import { VueRouter } from "vue-router/types/router";
import Http from "common/http/createHttp";
import VueI18n from "vue-i18n";
import bookingStepsEntry from "../../modules/bookingSteps/bookingSteps.entry";

export default (context: { store: Store<any>, router: VueRouter, http: typeof Http, i18n: VueI18n }) => {
  const { store } = context;

  PostMessage(context);
  Payment(context);
  FlyLocation(context);
  FlyOrder(context);
  FlySearch(context);
  FlyResult(context);
  i18nEntry(context);
  rootEntry(context);
  NotificationEntry(context);
  DynamicPackage(context);

  // tabs
  Tabs(context);

  // map
  mapModule(context);

  // booking steps

  bookingStepsEntry(context);

  // package app
  ProductSearch(context);
  ProductResult(context);
  ProductOrder(context);

  HotelLocation(context);

  store.registerModule(TL_DESKTOP, desktopStore);
  store.registerModule(PACKAGE_DESKTOP, packageStore);
  store.registerModule(HOTEL_DESKTOP, hotelStore);
};

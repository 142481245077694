import { Store } from "vuex";

import createApi from "./fly-search.api";
import FLY_SEARCH_MODULE from "./fly-search.name";
import createStore from "./fly-search.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store, http } = context;

  const api = createApi({ http });

  store.registerModule(FLY_SEARCH_MODULE, createStore({ api }));
};

import { getLocalStorage, setLocalStorage } from "../localStorageHelper";
import { ICity } from "../../modules/product-search/data/product-search.types";
import { isEqual } from "lodash";

export function isLocalStorageCities({ key }: {key: string}): boolean {
  return getLocalStorage({ key })?.length > 0;
}

export function setCity({ key, city }: {key: string, city: ICity}) {
  const cities:ICity[] = getLocalStorage({ key }) ?? [];
  if (cities?.length > 4) {
    const lastCity: number = cities.length - 1;
    cities.splice(lastCity, 1);
  }
  const cityIsNotDuplicate = cities.every(el => el.code !== city.code);
  if (cityIsNotDuplicate) {
    cities.unshift(city);
  }
  setLocalStorage({ key, value: cities });
}

export const localeCities = {
  en: [
    {
      city: {
        hasMultiairport: true,
        code: "LON",
        country: {
          name: "United Kingdom"
        },
        name: "London"
      },
      code: "LON"
    },
    {
      city: {
        hasMultiairport: true,
        code: "NYC",
        country: {
          name: "United States"
        },
        name: "New York"
      },
      code: "NYC"
    },
    {
      city: {
        hasMultiairport: true,
        code: "BCN",
        country: {
          name: "Spain"
        },
        name: "Barcelona"
      },
      code: "BCN"
    },
    {
      city: {
        hasMultiairport: true,
        code: "MAD",
        country: {
          name: "Spain"
        },
        name: "Madrid"
      },
      code: "MAD"
    },
    {
      city: {
        hasMultiairport: true,
        code: "PRG",
        country: {
          name: "Czech Republic"
        },
        name: "Prague"
      },
      code: "PRG"
    },
    {
      city: {
        hasMultiairport: true,
        code: "BUD",
        country: {
          name: "Hungary"
        },
        name: "Budapest"
      },
      code: "BUD"
    },
    {
      city: {
        hasMultiairport: true,
        code: "ROM",
        country: {
          name: "Italy"
        },
        name: "Rome"
      },
      code: "ROM"
    },
    {
      city: {
        hasMultiairport: true,
        code: "PAR",
        country: {
          name: "France"
        },
        name: "Paris"
      },
      code: "ORY"
    },
    {
      city: {
        hasMultiairport: true,
        code: "MIL",
        country: {
          name: "Italy"
        },
        name: "Milan"
      },
      code: "MXP"
    },
    {
      city: {
        hasMultiairport: true,
        code: "ATH",
        country: {
          name: "Greece"
        },
        name: "Athens"
      },
      code: "ATH"
    },
    {
      city: {
        hasMultiairport: true,
        code: "TBS",
        country: {
          name: "Georgia"
        },
        name: "Tbilisi"
      },
      code: "TBS"
    },
    {
      city: {
        hasMultiairport: true,
        code: "BAT",
        country: {
          name: "Brazil"
        },
        name: "Barretos"
      },
      code: "BAT"
    },
    {
      city: {
        hasMultiairport: true,
        code: "DXB",
        country: {
          name: "United Arab Emirates"
        },
        name: "Dubai"
      },
      code: "DXB"
    },
    {
      city: {
        hasMultiairport: true,
        code: "BKK",
        country: {
          name: "Thailand"
        },
        name: "Bangkok"
      },
      code: "BKK"
    },
    {
      city: {
        hasMultiairport: true,
        code: "MUC",
        country: {
          name: "Germany"
        },
        name: "Munich"
      },
      code: "MUC"
    },
    {
      city: {
        hasMultiairport: true,
        code: "BER",
        country: {
          name: "Germany"
        },
        name: "Berlin"
      },
      code: "BER"
    },
    {
      city: {
        hasMultiairport: true,
        code: "VIE",
        country: {
          name: "Austria"
        },
        name: "Vienna"
      },
      code: "VIE"
    }
  ]
};

import { ILocation, IService } from "./fly-location.types";

class Location implements ILocation {
  private _code: string;
  private _locale: string;
  private _name: string;

  get code(): string {
    return this._code;
  }

  get locale(): string {
    return this._locale;
  }

  get name(): string {
    return this._name;
  }
}

class City extends Location {
  private _country: Location = new Location();
  private _multiairport = false;

  constructor() {
    super();
  }

  get country(): Location {
    return this._country;
  }

  get multiairport(): boolean {
    return (this._multiairport = true);
  }

  set multiairport(val: boolean) {
    this.multiairport = val;
  }
}

class Airport extends Location {
  private _city: City = new City();

  constructor() {
    super();
  }

  get city(): City {
    return this._city;
  }
}

export { Location, City, Airport };

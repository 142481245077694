import Http from "common/http/createHttp";
import { ApiCurrencyRates, getPaymentCallbackUrls } from "./types/payment.types";
import {
  AgentVoucherPaymentCreate,
  CcPaymentEvaluationResource,
  DocumentStatus,
  PaymentTransactionResource,
  ReservationRequirementsResource,
  RootVoucherResource,
  VoucherStatusChangeResource
} from "tl-api-doc/typescript-generator";

export default ({ http }: { http: typeof Http }) => ({

  // get payment count
  getPaymentCount: ({ orderId }: { orderId: string }) => {
    return http.get<number[]>(`/customer/orders/${orderId}/paymentnumber`, {});
  },

  getPaymentMeans: ({
    orderId,
    uuid
  }: { uuid: string, orderId: string }) => {
    return http.get(`/agent/orders/${orderId}/paymentmeans?uuid=${uuid}`, {});
  },

  // cancel payment on close tab
  cancelPayment(orderId: string, uuid: string) {
    return http.put(
      `customer/orders/${orderId}/payment/cancel?uuid=${uuid}`,
      {}
    );
  },

  getPaymentUrl(
    orderId: string,
    uuid: string,
    numberOfPayments = 1,
    ccOwnerType: "CUSTOMER" | "AGENT" = "CUSTOMER",
    ccBrand: "DEFAULT" | "ISRACARD_AMEX" = "DEFAULT",
    amount?: number,
    voucherId?: string,
    issueTickets?: boolean
  ) {
    return http.post<{ paymentUrl: string }>(
      `customer/orders/${orderId}/pay`,
      {},
      {
        params: {
          numberOfPayments,
          ccOwnerType,
          ccBrand,
          voucherId,
          amount,
          issueTickets,
          ...getPaymentCallbackUrls(orderId, uuid)
        },
        headers: {
          "Accept-Language": "he"
        }
      }
    );
  },
  // get Reservation Requirements
  // PUT /customer/orders/requirements
  // Returns the amount of payment
  // IN: {@link CcEvaluateRequest}
  // OUT: {@link ReservationRequirementsResource}

  getReservationRequirements(orderId: string, uuid: string, numberOfPayments = 1, ccOwnerType: "CUSTOMER" | "AGENT" = "CUSTOMER", voucherId?: string, amount?: number) {
    return http.put<ReservationRequirementsResource>(
      "/customer/orders/requirements",
      {
        orderId,
        numberOfPayments,
        ccOwnerType,
        voucherId,
        amount
      },
      {
        params: {
          uuid
        }
      }
    );
  },

  getPaymentEvaluation(orderId: string, uuid: string, numberOfPayments = 1, ccOwnerType: "CUSTOMER" | "AGENT" = "CUSTOMER", voucherId?: string, amount?: number) {
    return http.put<CcPaymentEvaluationResource>("/customer/orders/pay/evaluate", {
      orderId,
      numberOfPayments,
      ccOwnerType,
      voucherId,
      amount
    });
  },
  getPaymentStatus(orderId: string) {
    return http.get<PaymentTransactionResource>(
      `customer/orders/${orderId}/transactions/last`
    );
  },
  getCurrencyRates(uuid: string) {
    return http.put<ApiCurrencyRates>(
      `/backoffice/rates?uuid=${uuid}`,
      {
        pageable: {
          offset: 0,
          pageSize: 50,
          sort: [{ fromCurrency: "ASC" }, { toCurrency: "ASC" }]
        }
      }
    );
  },
  // Create Voucher
  // /agent/orders/{orderId}/items/vouchers
  //   uuid: d06f6e6e-9128-3239-bb73-58ff70124550'
  //     '{
  //   "orderItemIds" : [ "b14e9101-5165-36fd-823a-251fa6e881ae" ],
  //       "name" : "Voucher",
  //       "remarkSubject" : "",
  //       "remarkBody" : "",
  //       "actionType" : "TICKET"
  // }'
  createVoucher(orderId: string, uuid: string, orderItemIds: string[]) {
    return http.post<RootVoucherResource>(
      `/agent/orders/${orderId}/items/vouchers?uuid=${uuid}`,
      {
        orderItemIds,
        name: "Voucher",
        remarkSubject: "",
        remarkBody: ""
      }
    );
  },

  changeVoucherStatus({
    uuid,
    orderId,
    orderItemId,
    dataVoucher,
    companyType
  }: {
        uuid: string
        orderId: string
        orderItemId: string
        dataVoucher: {
            voucherId: string
            remarkSubject: string
            remarkBody: string
            newStatus: DocumentStatus
            costCurrency: string
            costCommission: number
            costMarkup: number
        }
        companyType: string
    }) {
    if (companyType === "BACKOFFICE") {
      return http.post(`/backoffice/agencies/documents/vouchers?uuid=${uuid}`, {
        ...dataVoucher
      });
    } else {
      return http.post(
        `/agent/orders/${orderId}/items/${orderItemId}/vouchers/${
          dataVoucher.voucherId
        }?uuid=${uuid}`,
        { ...dataVoucher }
      );
    }
  },

  // pay for voucher from deposit
  // agent/orders/vouchers/payment
  // payload AgentVoucherPaymentCreate + uuid
  // return VoucherStatusChangeResource

  payForVoucherFromDeposit(uuid: string, payload: AgentVoucherPaymentCreate) {
    return http.post<VoucherStatusChangeResource>(`/agent/orders/vouchers/payment?uuid=${uuid}`, payload);
  }

});

import { IAxiosLog } from "applications/desktop/BugReportAutomation/types";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";

declare let WEBPACK_API_BASE_URL: string;
declare let PRINT_BASE_URL: string;

export let axiosLogs: IAxiosLog[] = [];

const requestLog = (request: InternalAxiosRequestConfig) => {
  const logData: IAxiosLog = {
    type: "request",
    method: request.method,
    url: request.url,
    status: null,
    headers: request.headers,
    payload: request.data,
    response: null,
    errorResponse: null
  };
  axiosLogs.push(logData);
  return request;
};

const responseLog = (response: AxiosResponse) => {
  if (!response) return response;
  const logData: IAxiosLog = {
    type: "response",
    method: response.config?.method,
    url: response.config.url,
    status: response.status,
    headers: response.headers,
    payload: response.config.data,
    response: response?.data,
    errorResponse: null
  };
  axiosLogs.push(logData);
  return response;
};
const responseErrorLog = (error: AxiosError) => {
  if (!error) return Promise.reject(error?.response);
  const logData: IAxiosLog = {
    type: "error",
    method: error.config?.method,
    url: error.config.url,
    status: error.response?.status,
    headers: error.response?.headers,
    payload: error.config.data,
    response: null,
    errorResponse: error.response?.data
  };
  axiosLogs.push(logData);
  return Promise.reject(error.response);
};

axios.interceptors.request.use((request) => requestLog(request));
axios.interceptors.response.use(
  (response) => responseLog(response),
  (error) => responseErrorLog(error)
);

export function clearAxiosLogs(): void {
  axiosLogs = [];
}

function getBaseUrl() {
  const sources = document.getElementById("wmsIf97865436901");
  if (!!sources && !!sources.getAttribute("terminalUrl")) {
    const terminalUrl = sources.getAttribute("terminalUrl");
    return terminalUrl + WEBPACK_API_BASE_URL;
  } else {
    return WEBPACK_API_BASE_URL;
  }
}

export function getPrintBaseUrl() {
  const sources = document.getElementById("wmsIf97865436901");
  if (!!sources && !!sources.getAttribute("terminalUrl")) {
    const terminalUrl = sources.getAttribute("terminalUrl");
    return terminalUrl + PRINT_BASE_URL;
  } else {
    return PRINT_BASE_URL;
  }
}

const instance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json"
  }
});

const instanceImg: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Accept: "image/*, application/json, text/plain, */*",
    "Content-Type": "image/*"
  }
});

const printApi: AxiosInstance = axios.create({
  baseURL: getPrintBaseUrl(),
  headers: {
    "Content-Type": "application/json"
  },
  responseType: "arraybuffer"
});

const resFn = (response: AxiosResponse) => response;
const rejFn = (error: AxiosError) => Promise.reject(error.response);
const instances: { [key: string]: AxiosInstance } = {
  instanceImg,
  instance,
  printApi
};
Object.keys(instances).forEach((key: string) => {
  instances[key].interceptors.response.use(resFn, rejFn);

  instances[key].interceptors.request.use((request) => requestLog(request));
  instances[key].interceptors.response.use(
    (response) => responseLog(response),
    (error) => responseErrorLog(error)
  );
});

export { instances };
export default class Http {
  public static post<T = any>(
    url: string,
    data: any,
    config?: AxiosRequestConfig<T>
  ): AxiosPromise<T> {
    return instance.post<T>(url, data, config);
  }

  public static get<T = any>(
    url: string,
    data?: any,
    headers?: any
  ): AxiosPromise<T> {
    return instance.get(url, { params: data, headers });
  }

  public static getImg(url: string, config?: AxiosRequestConfig): AxiosPromise {
    return instanceImg.get(url, config);
  }

  public static put<T = any>(
    url: string,
    data: any,
    config?: AxiosRequestConfig<T>
  ): AxiosPromise<T> {
    return instance.put<T>(url, data, config);
  }

  public static delete<T = any>(
    url: string,
    config?: AxiosRequestConfig<T>
  ): AxiosPromise<T> {
    return instance.delete<T>(url, config);
  }

  public static postPdf(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return printApi.post(url, data, config);
  }
}

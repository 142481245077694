import { Store } from "vuex";

import MAP_MODULE from "./map.name";
import createStore from "./map.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(MAP_MODULE, createStore());
};

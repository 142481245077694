import { Store } from "vuex";

// from "./bookingSteps.name";
import BookingStepsName from "./bookingSteps.name";

import createStore from "./bookingSteps.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(BookingStepsName, createStore());
};

import { ILocation } from "./hotel-location.types";

class Location implements ILocation {
  private _code: string;
  private _locale: string;
  private _name: string;

  get code(): string {
    return this._code;
  }

  get locale(): string {
    return this._locale;
  }

  get name(): string {
    return this._name;
  }
}

class City extends Location {
  private _country: Location = new Location();
  private _multiairport = false;

  constructor() {
    super();
  }

  get country(): Location {
    return this._country;
  }
}

export { Location, City };

import i18n from "applications/desktop/i18n";

import * as en from "./locales/en";
import * as he from "./locales/he";
import * as ru from "./locales/ru";

export default class Locale {
  private _loadedLocales: Set<string> = new Set();
  private _html: HTMLElement = document.getElementsByTagName("html")[0];
  private _messagesList: { [type: string]: { [keyword: string]: {} } } = {
    en,
    he,
    ru
  };

  constructor(private _locale: string) {}

  set locale(localeValue: string) {
    this._locale = localeValue;
  }

  get locale(): string {
    return this._locale;
  }

  get usedLocale(): string {
    return this.localeFromStorage || this.langHTMLAttribute || this._locale;
  }

  get formattedLocale(): string {
    switch (this.locale.toLowerCase()) {
    case "ru":
      return "ru-RU";
    case "en":
      return "en-US";
    case "he":
      return "iw-IL";
    default:
      return "en-US";
    }
  }

  get langHTMLAttribute(): string {
    if (this._html) {
      return this._html.getAttribute("lang");
    }

    return null;
  }

  get localeFromStorage(): string {
    return window.localStorage.getItem("app_locale") || null;
  }

  getLocaleMessagesList(locale: string): { [keyword: string]: {} } {
    return this._messagesList[locale];
  }

  changeLocale(locale: string): void {
    const list: { [keyword: string]: {} } = this.getLocaleMessagesList(
      locale.toLocaleLowerCase()
    );
    i18n.locale = locale;
    i18n.setLocaleMessage(locale, list.default);
  }

  applyLocale(locale: string) {
    if (this._html) {
      this._html.setAttribute("lang", locale);
    }
  }
}

import Http from "common/http/createHttp";

import { TabSessionResource } from "tl-api-doc/typescript-generator/assembly";
import {
  addFlightProductToTabSessionsHandler,
  addFlightProductToTabSessionsPayload,
  addHotelProductToTabSessionsHandler,
  addHotelProductToTabSessionsPayload,
  addStatPackageProductToTabSessionsHandler,
  addStatPackageProductToTabSessionsPayload,
  createTabSessionsHandler,
  createTabSessionsPayload,
  deleteTabSessionsHandler,
  deleteTabSessionsPayload,
  getTabSessionsHandler,
  getTabSessionsPayload,
  updateTabSessionsHandler,
  updateTabSessionsPayload
} from "./types/tabs.types";
import { ApiBaseResource } from "../../common/types/types";

export default ({ http }: { http: typeof Http }) => {
  const getTabSessions: getTabSessionsHandler = function(payload: getTabSessionsPayload) {
    return http.put<ApiBaseResource<TabSessionResource>>(
      `/agent/sessions/tabs/filter?uuid=${payload.uuid}`,
      payload
    );
  };

  const createTabSessions: createTabSessionsHandler = function(payload: createTabSessionsPayload) {
    return http.post<TabSessionResource>(`/agent/sessions/tabs?uuid=${payload.uuid}`, payload);
  };

  const updateTabSessions: updateTabSessionsHandler = function(tabSessionId: string, payload: updateTabSessionsPayload) {
    return http.put<TabSessionResource>(`/agent/sessions/tabs/${tabSessionId}?uuid=${payload.uuid}`, payload);
  };

  const addFlightProductToTabSessions: addFlightProductToTabSessionsHandler = function(tabSessionId: string, payload: addFlightProductToTabSessionsPayload) {
    return http.put<TabSessionResource>(
      `/agent/sessions/tabs/${tabSessionId}/products/flight?uuid=${payload.uuid}&productId=${payload.productId}`,
      {}
    );
  };

  const addHotelProductToTabSessions: addHotelProductToTabSessionsHandler = function(tabSessionId: string, payload: addHotelProductToTabSessionsPayload) {
    return http.put<TabSessionResource>(
      `/agent/sessions/tabs/${tabSessionId}/products/hotel?uuid=${payload.uuid}&productId=${payload.productId}`,
      {}
    );
  };

  const addStatPackageProductToTabSessions: addStatPackageProductToTabSessionsHandler = function(tabSessionId: string, payload: addStatPackageProductToTabSessionsPayload) {
    return http.put<TabSessionResource>(
      `/agent/sessions/tabs/${tabSessionId}/products/package?uuid=${payload.uuid}&productId=${payload.productId}`,
      {}
    );
  };

  const deleteTabSessions: deleteTabSessionsHandler = function(tabSessionId: string, payload: deleteTabSessionsPayload) {
    return http.delete<void>(`/agent/sessions/tabs/${tabSessionId}?uuid=${payload.uuid}`);
  };

  return {

    // get tabs resource
    getTabSessions,

    // create tabs resource

    createTabSessions,

    // update tabs resource
    updateTabSessions,

    // add flight product to tabs resource
    addFlightProductToTabSessions,

    // add hotel product to tabs resource
    addHotelProductToTabSessions,

    // add Stat Package product to tabs resource
    addStatPackageProductToTabSessions,

    // Delete Tab Session
    deleteTabSessions
  };
};

import { Store, StoreOptions } from "vuex";

import PRODUCT_RESULT_MODULE from "modules/product-result/product-result.name";
import { packageResultStore } from "modules/product-result/product-result.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(PRODUCT_RESULT_MODULE, packageResultStore);
};

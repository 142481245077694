import {
  IPackageProductDetailsClass,
  IProductDetailsPaxComplectClass,
  ProductDetailsPaxComplect
} from "modules/product-result/data/product-result.types";
import { PaxComplectsResource } from "tl-api-doc/typescript-generator";

export default class PackageProductDetails
implements IPackageProductDetailsClass {
  private _complects: IProductDetailsPaxComplectClass[] = [];

  constructor(private _details: PaxComplectsResource) { // TODO change to PaxComplectsResource
    this._complects = this._details.complects.map(complect => {
      this._complectsIds.push(complect.entityId);
      return new ProductDetailsPaxComplect(complect);
    });
  }

  private _complectsIds: string[] = [];

  get complectsIds(): string[] {
    return this._complectsIds;
  }

  get content(): IPackageProductDetailsClass["content"] {
    return this._details;
  }

  get paxComplects(): IProductDetailsPaxComplectClass[] {
    return this._complects;
  }
}

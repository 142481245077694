import { Store } from "vuex";

import PAYMENT_MODULE from "modules/payment/payment.name";
import createStore from "modules/payment/payment.store";
import paymentApi from "./payment.api";
import Http from "../../common/http/createHttp";

export default (context: { store: Store<null>; http: typeof Http }): void => {
  const { store, http } = context;
  const api = paymentApi({ http });

  store.registerModule(PAYMENT_MODULE, createStore({ api }));
};

// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";
import { IFlyLocationRequest } from "./types/fly-location.types";
import { IATAAirportResource } from "tl-api-doc/typescript-generator";

const findCountries = (request: IFlyLocationRequest) => {
  return http.get("/places/countries", request);
};

const findCities = (request: IFlyLocationRequest) => {
  return http.get("/places/cities", request);
};

const findAirports = (request: IFlyLocationRequest) => {
  return http.get<IATAAirportResource[]>(
    "/places/airports",
    { term: request.term },
    {
      "Accept-Language": request.locale
    }
  );
};

const findAirportsByIP = (locale: string) => {
  return http.get(
    "/places/airports/geoip",
    {},
    {
      "Accept-Language": locale
    }
  );
};

export default {
  findAirports,
  findAirportsByIP,
  findCities,
  findCountries
};

import { Store, StoreOptions } from "vuex";

import PRODUCT_ORDER_MODULE from "modules/product-order/product-order.name";
import { productOrderStore } from "modules/product-order/product-order.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(PRODUCT_ORDER_MODULE, productOrderStore);
};

import { ActionContext, Module } from "vuex";

import actionHelper from "utils/actionHelper";
import { IPostMessageState } from "./types/post-message.types";
import POST_MESSAGE_MODULE from "modules/post-message/post-message.name";
import i18nName from "../i18n/i18n.name";
import { RootState } from "../../applications/desktop/store";
import FLY_ORDER from "../fly-order/fly-order.name";

// TODO: realize helper-module for action-mutation
const SENDING = actionHelper("SENDING");
const SET_SENDING_DATA = actionHelper("SET_SENDING_DATA");
const SET_RECEIPT_DATA = actionHelper("SET_RECEIPT_DATA");
const SET_VOUCHER_POPUP_SENDING = actionHelper("SET_VOUCHER_POPUP_SENDING");

export default (): Module<IPostMessageState, RootState> => ({
  namespaced: true,

  state: {
    isSending: false,
    isSendingError: false,
    sendingError: null,
    isReceipting: false,
    isReceiptingError: false,
    receiptingError: null,
    sendMessagePayload: {
      type: null,
      data: null
    },
    receiptMessagePayload: {
      type: null,
      data: null
    },
    voucherPopupData: {
      voucherFinish: false,
      isIssueProcessing: false,
      isError: false,
      error: null
    }
  },

  getters: {
    isSending: (state: IPostMessageState) => state.isSending,
    isSendingError: (state: IPostMessageState) => state.isSendingError,
    sendingError: (state: IPostMessageState) => state.sendingError,
    isReceipting: (state: IPostMessageState) => state.isReceipting,
    receiptingError: (state: IPostMessageState) => state.receiptingError,
    isReceiptingError: (state: IPostMessageState) => state.isReceiptingError,
    sendMessagePayload: (state: IPostMessageState) => state.sendMessagePayload,
    receiptMessagePayload: (state: IPostMessageState) => state.receiptMessagePayload,
    voucherPopupData: (state: IPostMessageState) => state.voucherPopupData
  },

  actions: {
    async pushMessage(context: ActionContext<IPostMessageState, RootState>) {
      context.commit(SENDING.INITIAL);
      try {
        const data = (context.getters as IPostMessageState).sendMessagePayload;
        window.top.postMessage(JSON.stringify(data), "*");

        context.commit(SENDING.SUCCEEDED);
        return Promise.resolve();
      } catch (e) {
        context.commit(SENDING.FAILED, e);
        return Promise.reject(e);
      }
    },
    async sendVoucherOpenMessage(context: ActionContext<IPostMessageState, RootState>, payload: {
      orderId: string,
      itemId: string
    }) {
      context.commit(SET_VOUCHER_POPUP_SENDING.INITIAL);
      context.commit(SET_SENDING_DATA.INITIAL);
      try {
        const data: { type: string, data: any } = {
          type: "POST_MESSAGE_MODULE/voucherIssueMessage",
          data: {
            orderId: payload.orderId,
            itemId: payload.itemId
          }
        };
        context.commit(SET_SENDING_DATA.SUCCEEDED, data);

        return context.dispatch("pushMessage");
      } catch (e) {
        context.commit(SET_SENDING_DATA.FAILED, e);
        return Promise.reject(e);
      }
    },
    async catchReceiptData(context, payload: { type: string, data: any }) {
      context.commit(SET_RECEIPT_DATA.INITIAL);
      try {
        if (!payload.type || !payload.data) {
          throw new Error("Invalid message data");
        }
        context.commit(SET_RECEIPT_DATA.SUCCEEDED, payload);
        return context.dispatch(payload.type, null, { root: true });
      } catch (e) {
        context.commit(SET_RECEIPT_DATA.FAILED, e);
        return Promise.reject(e);
      }
    },
    async finishVoucherIssue(context) {
      const payload = (context.getters as IPostMessageState).receiptMessagePayload;
      if (payload?.data?.orderId) {
        await context.dispatch(`${FLY_ORDER}/getTravelOrderResource`, { orderId: payload.data.orderId }, { root: true });
      }
      context.commit(SET_VOUCHER_POPUP_SENDING.SUCCEEDED);
    },
    async changeLocaleFromAdmin(context): Promise<void> {
      const data = (context.getters as IPostMessageState).receiptMessagePayload;
      if (data.type !== `${POST_MESSAGE_MODULE}/changeLocaleFromAdmin`) {
        return Promise.reject(new Error("Wrong message type"));
      }
      const { locale }: {
        locale: string,
      } = data.data;
      return context.dispatch(`${i18nName}/changeLocale`, locale, { root: true });
    },
    async openDocketMessage(context: ActionContext<IPostMessageState, RootState>, payload) {
      context.commit(SET_SENDING_DATA.INITIAL);
      try {
        const data: { type: string, data: any } = {
          type: "redirect",
          data: {
            name: "Edit order",
            params: {
              orderNumber: payload.orderNumber
            },
            query: null
          }
        };
        context.commit(SET_SENDING_DATA.SUCCEEDED, data);
        return context.dispatch("pushMessage");
      } catch (e) {
        context.commit(SET_SENDING_DATA.FAILED, e);
        return Promise.reject(e);
      }
    }
  },

  mutations: {
    [SENDING.INITIAL](state) {
      state.isSending = true;
    },
    [SENDING.SUCCEEDED](state) {
      state.isSending = false;
      state.sendingError = null;
    },
    [SENDING.FAILED](state, error) {
      state.isSending = false;
      state.sendingError = error;
    },
    [SET_SENDING_DATA.INITIAL](state) {
      state.sendMessagePayload.type = null;
      state.sendMessagePayload.data = null;
    },
    [SET_SENDING_DATA.SUCCEEDED](state, payload: { type: string, data: any }) {
      state.sendMessagePayload.type = payload.type;
      state.sendMessagePayload.data = payload.data;
    },
    [SET_SENDING_DATA.FAILED](state, error) {
      state.sendMessagePayload.type = null;
      state.sendMessagePayload.data = null;
      state.sendingError = error;
    },
    [SET_RECEIPT_DATA.INITIAL](state) {
      state.receiptMessagePayload.type = null;
      state.receiptMessagePayload.data = null;
      state.receiptingError = null;
      state.isReceipting = true;
    },
    [SET_RECEIPT_DATA.SUCCEEDED](state, payload: { type: string, data: any }) {
      state.receiptMessagePayload.type = payload.type;
      state.receiptMessagePayload.data = payload.data;
      state.receiptingError = null;
      state.isReceipting = true;
    },
    [SET_RECEIPT_DATA.FAILED](state, error) {
      state.receiptMessagePayload.type = null;
      state.receiptMessagePayload.data = null;
      state.receiptingError = error;
      state.isReceipting = true;
    },
    [SET_VOUCHER_POPUP_SENDING.INITIAL](state) {
      state.voucherPopupData = {
        voucherFinish: false,
        isIssueProcessing: true,
        isError: false,
        error: null
      };
    },
    [SET_VOUCHER_POPUP_SENDING.SUCCEEDED](state) {
      state.voucherPopupData = {
        voucherFinish: true,
        isIssueProcessing: false,
        isError: false,
        error: null
      };
    },
    [SET_VOUCHER_POPUP_SENDING.FAILED](state, error) {
      state.voucherPopupData = {
        voucherFinish: false,
        isIssueProcessing: false,
        isError: true,
        error: null
      };
    }
  }
});

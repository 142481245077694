import { Module } from "vuex";
import { RootState } from "../../applications/desktop/store";

export interface BookingStepsState {
    currentStep: number
}

export default (): Module<BookingStepsState, RootState> => ({
  namespaced: true,
  state: {
    currentStep: 0 // Начальный этап
  },
  mutations: {
    // Мутация для установки текущего этапа
    SET_CURRENT_STEP(state, step) {
      state.currentStep = step;
    }
  },
  actions: {
    // Действие для перехода к следующему этапу
    nextStep({ commit, state }) {
      const nextStep = state.currentStep + 1;
      commit("SET_CURRENT_STEP", nextStep);
    },
    // Действие для перехода к предыдущему этапу
    prevStep({ commit, state }) {
      const prevStep = state.currentStep - 1;
      if (prevStep >= 0) {
        commit("SET_CURRENT_STEP", prevStep);
      }
    },
    // Действие для перехода к определенному этапу
    goToStep({ commit }, step) {
      commit("SET_CURRENT_STEP", step);
    },
    // Сбросить к начальному состоянию
    resetSteps({ commit }) {
      commit("SET_CURRENT_STEP", 0);
    }
  },
  getters: {
    // Получить текущий этап
    currentStep(state) {
      return state.currentStep;
    }
  }
});

import { Store } from "vuex";

import POST_MESSAGE_MODULE from "modules/post-message/post-message.name";
import createStore from "modules/post-message/post-message.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(POST_MESSAGE_MODULE, createStore());
};


import Vue from "vue";
import { Component } from "vue-property-decorator";

import TlIcon from "../icons/icon";

@Component({
  components: {
    TlIcon
  }
})
export default class SearchWithLoaderPending extends Vue {
}


import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";

import TlIcons from "ui-modules/icons/icon";
import ROOT_MODULE from "modules/root/root.name";

@Component({
  name: "AccessBtn",
  components: {
    TlIcons
  }
})
export default class AccessBtn extends Vue {
  @Getter(`${ROOT_MODULE}/isAccessibility`)
    isAccessibility: boolean;

  @Getter(`${ROOT_MODULE}/isB2b`)
    isB2b: boolean;

  @Action(`${ROOT_MODULE}/toggleAccessibility`)
    toggleAccessibility: (status: boolean) => Promise<void>;

  position = {
    top: 5, // начальное положение
    left: 35
  };

  dragging = false;
  start = { x: 0, y: 0 };

  get isRTL(): boolean {
    return document.body.getAttribute("dir") === "rtl";
  }

  created() {
    // if ltr change left value
    this.position.left = this.isRTL ? 35 : window.innerWidth - 150;
  }

  startDrag(event: MouseEvent) {
    this.dragging = true;
    this.start.x = event.clientX - this.position.left;
    this.start.y = event.clientY - this.position.top;

    document.addEventListener("mousemove", this.onDrag);
    document.addEventListener("mouseup", this.stopDrag);
  }

  onDrag(event: MouseEvent) {
    if (!this.dragging) return;
    this.position.left = event.clientX - this.start.x;
    this.position.top = event.clientY - this.start.y;
  }

  stopDrag() {
    this.dragging = false;
    document.removeEventListener("mousemove", this.onDrag);
    document.removeEventListener("mouseup", this.stopDrag);
  }
}

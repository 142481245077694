import {
  IPackage,
  IPackageClass,
  IPackageHotel,
  IPackageHotelClass,
  IPackageProduct,
  IPackageProductsListClass,
  PackageHotel,
  PackageProductsList
} from "modules/product-result/data/product-result.types";

export default class Package implements IPackageClass {
  constructor(private _package: IPackage) {
    this._hotel = new PackageHotel(_package.hotel);
    // const t = JSON.parse(JSON.stringify(_package.products))
    if (_package.products) {
      this._products = new PackageProductsList(_package.products);
    }
    this._isDetailsPending = false;
    this._isShowDetails = false;

    // this._package.products.map(product => this._productsIds.push(product.productId))
  }

  // private _productsPrices: IPackageProductPrice[]

  // export default class Package implements IPackageClass {
  private _hotel: IPackageHotelClass;

  get hotel(): IPackageHotelClass {
    return this._hotel;
  }

  set hotel(hotel: IPackageHotel | IPackageHotelClass) {
    this._hotel = hotel instanceof PackageHotel ? hotel : new PackageHotel(<IPackageHotel>hotel);
  }

  // private _productsIds: Array<IPackageProduct['productId']> = []
  private _products: IPackageProductsListClass;

  get products(): IPackageProductsListClass {
    return this._products;
  }

  set products(products: IPackageProduct[] | IPackageProductsListClass) {
    this._products =
            products instanceof PackageProductsList ? products : new PackageProductsList(<IPackageProduct[]>products);
  }

  private _isOpening = false;

  get isOpening(): boolean {
    return this._isOpening;
  }

  set isOpening(value: boolean) {
    this._isOpening = value;
  }

  private _isDetailsPending: boolean;

  get isDetailsPending(): boolean {
    return this._isDetailsPending;
  }

  private _isShowDetails: boolean;

  get isShowDetails(): boolean {
    return this._isShowDetails;
  }

  set isShowDetails(value: boolean) {
    this._isShowDetails = value;
  }

  get content(): IPackageClass["content"] {
    return this._package;
  }

  get isDetailsError(): boolean {
    return this._products.content.every(product => product.details && !!product.details.status &&
            product.details.status === "ERROR") && this._products.content.length > 0;
  }

  setDetailsPending(status: boolean): void {
    this._isDetailsPending = status;
  }

  convertToStorage(): any {
    return {
      content: this.content,
      details: this.products.content.map(pr => [
        pr.content?.productId,
        pr.details?.content
      ]),
      prices: this.products.pricesList,
      products: this.products.content
    };
  }
}

import { Store } from "vuex";

import DYNAMIC_PACKAGE_MODULE from "./dynamic-package.name";
import { dynamicPackageStore } from "./dynamic-package.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(DYNAMIC_PACKAGE_MODULE, dynamicPackageStore);
};

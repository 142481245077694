import { INotification, INotificationState } from "./data/notification.types";
import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "applications/desktop/store";

const SET_NOTIFICATION = "SET_NOTIFICATION";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const SET_STATUS_NOTIFICATION = "SET_STATUS_NOTIFICATION";
const namespaced = true;

const state: INotificationState = {
  notification: null,
  isNotVisibleNotification: true
};

const getters: GetterTree<INotificationState, RootState> = {
  notification: (state: INotificationState) => state.notification,
  isNotVisibleNotification: (state: INotificationState) => state.isNotVisibleNotification
};

const mutations: MutationTree<INotificationState> = {
  [SET_NOTIFICATION](state, notification: INotification) {
    state.notification = notification || null;
  },
  [DELETE_NOTIFICATION](state) {
    state.notification = null;
  },
  [SET_STATUS_NOTIFICATION](state, status: boolean) {
    state.isNotVisibleNotification = status;
  }
};

export const notificationStore: Module<INotificationState, RootState> = {
  mutations,
  state,
  getters,
  namespaced
};

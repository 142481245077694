// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";

import { HotelRequestPayload, IConvertedQuery } from "modules/product-search/data/product-search.types";

const beginSearch = (query: IConvertedQuery) => {
  return http.post("/customer/search/flight/requests", { ...query });
};

const beginPackageSearch = (query: IConvertedQuery, aia: boolean, websiteKey: string) => {
  if (websiteKey) {
    return http.post("/customer/search/statpackage/requests", {
      ...query,
      websiteKey,
      websiteType: aia ? "AGENCY_TERMINAL" : "AGENCY_SHOWCASE"
    });
  } else {
    return http.post("/customer/search/statpackage/requests", { ...query });
  }
};

const beginHotelSearch = (payload: HotelRequestPayload) => {
  return http.post("/customer/search/hotels/requests", {
    ...payload
  });
};

const getActiveDestinationCountries = (websiteKey: string, locale?: string) => {
  return http.get(`/customer/search/statpackage/calendar/countries?websiteKey=${websiteKey}`, {}, {
    "Accept-Language": locale
  });
};

const getActiveDestinations = (websiteKey: string, countryCode?: string, query?: string, locale?: string) => {
  return http.get(`/customer/search/statpackage/calendar/cities?websiteKey=${websiteKey}`, {
    countryCode,
    query
  }, {
    "Accept-Language": locale
  });
};

const getDepartureDates = (websiteKey: string, cityCode: string) => {
  return http.get(
    `/customer/search/statpackage/calendar/cities/${cityCode}/departures?websiteKey=${websiteKey}`,
    {}
  );
};

const getReturnDates = (websiteKey: string, cityCode: string, departureDate: string) => {
  return http.get(
    `/customer/search/statpackage/calendar/cities/${cityCode}/departures/${departureDate}/returns?websiteKey=${websiteKey}`,
    {}
  );
};

export default {
  beginHotelSearch,
  beginPackageSearch,
  beginSearch,
  getActiveDestinationCountries,
  getActiveDestinations,
  getDepartureDates,
  getReturnDates
};

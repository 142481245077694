// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";

import { ICustomer } from "./data/product-order.types";
import { CustomerResource } from "tl-api-doc/typescript-generator";

const getAgencyLogo = (agencyId: string, size = "") => {
  const query: string = size ? `${agencyId}?size=${size}` : agencyId;
  return http.getImg(`/logo/${query}`, {
    responseType: "blob"
  });
};

const findCountry = (request: { term: string; locale: string }) => {
  return http.get(
    "/places/countries",
    { term: request.term },
    {
      "Accept-Language": request.locale
    }
  );
};

const getAgencyPrices = (requestId: string, productIds: string[]) => {
  return http.get(
    `/customer/search/statpackage/requests/${requestId}/prices?${encodeURIComponent(
      "productIds[]"
    )}=${productIds}`,
    {
      pricePerProductLimit: 10,
      pricePerProductOffset: 0
    }
  );
};

const getProductDetails = (productId: string) => {
  return http.get(
    `/customer/search/statpackage/products/${productId}/details`,
    {}
  );
};
const getDictionaryBoarding = () => {
  const headers = {
    Accept: "application/hal+json",
    "Content-Type": "application/x-www-form-urlencoded"
  };
  return http.get(
    "/customer/search/statpackage/dictionary/boarding",
    {},
    headers
  );
};

const beginOrder = ({
  agencyId,
  uuid
}: { agencyId: string, uuid: string }) => {
  return http.post(`/customer/orders?agencyId=${agencyId}&uuid=${uuid}`, {});
};

const addProductToOrder = (
  orderId: string,
  itemId: string,
  complectIds?: string[]
) => {
  return http.post(
    `/customer/orders/statpackages/${orderId}?itemId=${itemId}&${encodeURIComponent(
      "complectIds[]"
    )}=${complectIds}`,
    {}
  );
};

const addProductToOrderForHotels = (
  orderId: string,
  itemId: string,
  complectIds?: string[]
) => {
  return http.post(
    `/customer/orders/hotels/${orderId}?itemId=${itemId}&${encodeURIComponent(
      "complectIds[]"
    )}=${complectIds}`,
    {}
  );
};

const deleteOrderItemFromOrder = (orderId: string, itemId: string, uuid: string) => {
  // agent/orders/${orderId}/items/${itemId}
  return http.delete(`/agent/orders/${orderId}/items/${itemId}?uuid=${uuid}`, {});
};

const registrationNewCustomer = (orderId: string, customer: ICustomer) => {
  return http.post<CustomerResource>(`/customer/orders/${orderId}/customers`, { ...customer });
};

const addBaggageToCustomer = (orderId: string, itemId: string, customerId: string, additionalPayment: FormData) => {
  const configCustom = {
    headers: {
      Accept: "application/hal+json",
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
  return http.post(
    `/customer/orders/statpackages/${orderId}/items/${itemId}/customers/${customerId}/additions`,
    additionalPayment,
    configCustom
  );
};

const addBaggageToCustomerForHotels = (orderId: string, itemId: string, customerId: string, additionalPayment: FormData) => {
  const configCustom = {
    headers: {
      Accept: "application/hal+json",
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
  return http.post(
    `/customer/orders/hotels/${orderId}/items/${itemId}/customers/${customerId}/additions`,
    additionalPayment,
    configCustom
  );
};

const setContactPerson = (orderId: string, customerId: string) => {
  return http.put(
    `/customer/orders/${orderId}/contact_person?customerId=${customerId}`,
    {}
  );
};

const addCustomerToOrderItem = (
  orderId: string,
  itemId: string,
  customerId: string
) => {
  return http.post(
    `/customer/orders/${orderId}/${itemId}/passengers?customerId=${customerId}`,
    {}
  );
};

const addCustomerToOrderItemNew = (
  orderId: string,
  itemIds: string[],
  customerId: string,
  roomKey: string,
  paxPriceDetailId?: string // TODO: узнать зачем нужен, сейчас пока пустой
) => {
  return http.put(
    `/customer/orders/${orderId}/passengers1`,
    {
      itemIds,
      customerId,
      paxPriceDetailId,
      roomKey
    }
  );
};

const startOrderReservation = (orderId: string, onlinePayment: boolean) => {
  return http.post(`customer/orders/${orderId}/reservations`, {
    onlinePayment
  });
};

const getReservationStatus = (orderId: string) => {
  return http.get(`customer/orders/${orderId}/reservation_status`, {});
};

const startOrderPreReservation = (orderId: string) => {
  return http.get(`customer/orders/${orderId}/pre_reservation_status`, {});
};

const getPackageOrderItems = (orderId: string) => {
  return http.get(`customer/orders/statpackages/${orderId}/items`, {});
};

const getPackageOrderItemsForHotels = (orderId: string) => {
  return http.get(`customer/orders/hotels/${orderId}/items`, {});
};

const getConfirmationStatus = (orderId: string) => {
  return http.get(`customer/orders/${orderId}/confirmation_status`, {});
};

const setMarkupToOrder = ({
  orderId,
  orderItemId,
  markup
}: { orderId: string, orderItemId: string, markup: number }) => {
  return http.post(
    `/customer/orders/${orderId}/${orderItemId}/markup/manual`,
    {
      manualMarkupAmount: markup
    }
  );
};

const getPaxType = ({
  itemId,
  birthday,
  serviceType
}: {
  itemId: string,
  birthday: string,
  serviceType: "HOTEL" | "STATIC_PACKAGE"
}) => {
  const headers = {
    Accept: "application/hal+json"
  };
  return http.get(
    serviceType === "STATIC_PACKAGE"
      ? `/customer/orders/statpackages/paxType/${itemId}?birthday=${birthday}`
      : `/customer/orders/hotels/paxType/${itemId}?birthday=${birthday}`,
    {},
    headers
  );
};

const getPaxTypeByComplectId = ({
  paxComplectId,
  itemId,
  birthday
}: {
  paxComplectId: string,
  itemId: string,
  birthday: string,
}) => {
  const headers = {
    Accept: "application/hal+json"
  };
  return http.get(
    `/customer/orders/statpackages/paxType/${itemId}/${paxComplectId}?birthday=${birthday}`,
    {},
    headers
  );
};

const getOrder = ({ orderId }: { orderId: string }) => {
  return http.get(`customer/orders/${orderId}`, {});
};

export default {
  getConfirmationStatus,
  addCustomerToOrderItem,
  addCustomerToOrderItemNew,
  addProductToOrder,
  addProductToOrderForHotels,
  deleteOrderItemFromOrder,
  beginOrder,
  findCountry,
  getAgencyLogo,
  getAgencyPrices,
  getPackageOrderItems,
  getPackageOrderItemsForHotels,
  getProductDetails,
  getReservationStatus,
  registrationNewCustomer,
  setContactPerson,
  startOrderPreReservation,
  startOrderReservation,
  addBaggageToCustomer,
  addBaggageToCustomerForHotels,
  getDictionaryBoarding,
  setMarkupToOrder,
  getPaxType,
  getOrder,
  getPaxTypeByComplectId
};

import FlySearchQuery from "./fly-search-query.class";
import { IMinMax } from "./fly-search.types";

export default class FlySearchResponse {
  private _body: FlySearchQuery;
  private _requestId: string;
  private _expireAt: string;

  get body(): FlySearchQuery {
    return this._body;
  }

  get requestId(): string {
    return this._requestId;
  }

  get expireAt(): string {
    return this._expireAt;
  }
}

import { ActionContext, Module } from "vuex";
import { QueryTypes } from "modules/product-search/data/product-search.consts";
import {
  AbstractRequestResource,
  CustomerResource,
  StatPackageSearchRequestResource
} from "tl-api-doc/typescript-generator";
import {
  IDiynamicPackageState,
  IStartFlyBookAfterHotelHandler,
  IStartFlyBookAfterHotelPayload,
  IStartHotelBookAfterFlyHandler,
  IStartHotelBookAfterFlyPayload
} from "./types/dynamic-package.types";
import PRODUCT_SEARCH from "modules/product-search/product-search.name";
import FLY_SEARCH from "modules/fly-search/fly-search.name";
import ROOT_MODULE from "modules/root/root.name";
import PackageQuery from "../product-search/data/product-search.package-query/product-search.package-query.class";
import { ICustomer } from "../fly-order/types/fly-order.types";

import api from "./dynamic-package.api";
import { RootState } from "../../applications/desktop/store";

const SET_CUSTOMERS = "SET_CUSTOMERS";
const SET_ORDER_ID = "SET_ORDER_ID";
const REMOVE_ORDER_ID = "REMOVE_ORDER_ID";
export const dynamicPackageStore: Module<IDiynamicPackageState, RootState> = {
  namespaced: true,

  state: {
    customer: null,
    orderId: null,
    customers: null
  },

  getters: {
    customer: (state: IDiynamicPackageState): CustomerResource => state.customer,
    orderId: (state: IDiynamicPackageState): string => state.orderId,
    isSearchFromOrder: (state: IDiynamicPackageState): boolean => !!state.orderId,
    customers: (state: IDiynamicPackageState) => state.customers
  },

  mutations: {
    [SET_CUSTOMERS](state, customers: ICustomer[]) {
      state.customers = customers;
    },
    [SET_ORDER_ID](state, orderId: string) {
      state.orderId = orderId;
    },
    [REMOVE_ORDER_ID](state) {
      state.orderId = null;
    }
  },
  actions: {
    async clearCustomer({ commit }: ActionContext<IDiynamicPackageState, RootState>) {
      commit(SET_CUSTOMERS, null);
    },
    async startHotelBookAfterFly({
      commit,
      dispatch,
      rootGetters
    }: ActionContext<IDiynamicPackageState, RootState>, {
      customers,
      query,
      orderId
    }: IStartHotelBookAfterFlyPayload): ReturnType<IStartHotelBookAfterFlyHandler> {
      commit(SET_ORDER_ID, orderId);
      commit(SET_CUSTOMERS, customers);
      try {
        const readyQuery: PackageQuery = new PackageQuery(query);
        await dispatch(`${PRODUCT_SEARCH}/saveQuery`,
          {
            query: readyQuery.content,
            queryType: QueryTypes.hotel
          }, { root: true }
        );

        await dispatch(`${PRODUCT_SEARCH}/initialQuery`,
          {
            queryType: QueryTypes.hotel,
            ...query
          }, { root: true }
        );
        const requestId = ((await dispatch(`${PRODUCT_SEARCH}/beginSearch`, {
          aia: rootGetters[`${ROOT_MODULE}/isTerminalSearch`],
          query: readyQuery,
          typeQuery: QueryTypes.hotel,
          websiteKey: rootGetters[`${ROOT_MODULE}/websiteKey`]
        }, { root: true })) as StatPackageSearchRequestResource).requestId;
        await dispatch(`${PRODUCT_SEARCH}/saveQuery`,
          {
            query: rootGetters[`${PRODUCT_SEARCH}/searchQuery`].content,
            queryType: QueryTypes.hotel
          }, { root: true }
        );
        return { requestId, query: readyQuery };
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },

    async startFlyBookAfterHotel({
      commit,
      dispatch,
      rootGetters
    }: ActionContext<IDiynamicPackageState, RootState>, {
      customers,
      query,
      orderId
    }: IStartFlyBookAfterHotelPayload): ReturnType<IStartFlyBookAfterHotelHandler> {
      commit(SET_ORDER_ID, orderId);
      commit(SET_CUSTOMERS, customers);
      try {
        const requestId = ((await dispatch(`${FLY_SEARCH}/beginSearch`, {
          aia: rootGetters[`${ROOT_MODULE}/isTerminalSearch`],
          query,
          typeQuery: QueryTypes.hotel,
          websiteKey: rootGetters[`${ROOT_MODULE}/websiteKey`]
        }, { root: true })) as AbstractRequestResource).requestId;

        return { requestId };
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },

    async removeOrderId({ commit, state }: ActionContext<IDiynamicPackageState, RootState>) {
      // commit(REMOVE_ORDER_ID);
      console.error("removeOrderId", state.orderId);
    },
    async finishMergeMove({ // Объединение заказа полёта с отелем
      commit,
      state
    }: ActionContext<IDiynamicPackageState, RootState>, {
      orderId
    }: { orderId: string }) {
      const targetId = state.orderId;
      commit("REMOVE_ORDER_ID");
      return await api.moveItemsToOrder(orderId, targetId);
    }
  }
};

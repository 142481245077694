
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import TlIcons from "ui-modules/icons/icon";
import {
  closeTabSessionsActionHandler,
  ITab,
  ITabsState,
  openNewTabActionHandler,
  selectActiveTabActionHandler
} from "modules/tabs/types/tabs.types";

import ROOT_MODULE from "modules/root/root.name";
import TabsName from "modules/tabs/tabs.name";

import TabQuery from "./TabQuery.vue";
import PackageServiceIcon from "./icons/PackageServiceIcon.vue";

@Component({
  components: {
    PackageServiceIcon,
    TabQuery,
    TlIcons
  }
})
export default class ResultSearchOrdersTabs extends Vue {
  @Getter(`${TabsName}/activeTabIndex`)
    activeTabIndex: ITabsState["activeTabIndex"];

  @Getter(`${TabsName}/tabs`)
    tabs: ITabsState["tabs"];

  @Getter(`${TabsName}/selectedTabId`)
    selectedTabId: ITabsState["selectedTabId"];

  @Getter(`${TabsName}/selectedTab`)
    selectedTab?: ITab;

  @Getter(`${ROOT_MODULE}/uuid`)
    uuid: string;

  @Action(`${TabsName}/selectActiveTab`)
    selectActiveTab: selectActiveTabActionHandler;

  @Action(`${TabsName}/closeTab`)
    closeTab: closeTabSessionsActionHandler;

  @Action(`${TabsName}/openNewTab`)
    openNewTab: openNewTabActionHandler;

  onSelectTab(tabSessionId: string) {
    this.selectActiveTab({
      tabSessionId,
      fromTab: this.selectedTab,
      uuid: this.uuid
    });
  }

  onCloseTab(tabSessionId: string) {
    this.closeTab({
      tabSessionId,
      uuid: this.uuid
    });
  }

  onNewSelect() {
    this.openNewTab({
      uuid: this.uuid,
      fromTab: this.selectedTab
    });
  }
}

// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";
import { IHotelLocationRequest } from "./types/hotel-location.types";

const findCities = (queryParams: Omit<IHotelLocationRequest, "locale">, locale: string) => {
  return http.get(
    "/customer/search/hotels/cities",
    queryParams,
    {
      "Accept-Language": locale
    }
  );
};

export default {
  findCities
};


import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class PackageServiceIcon extends Vue {
  @Prop(String)
    color: string;

  @Prop({
    default: "30px",
    type: String
  })
    width: string;

  @Prop({
    default: "30px",
    type: String
  })
    height: string;

  get iconStyle(): Partial<CSSStyleDeclaration> {
    return {
      height: this.height,
      width: this.width
    };
  }

  get iconSrc(): string {
    switch (this.color) {
    case "primary":
      return require("./package_icon--primary.svg").default;
    default:
      return require("./package_icon.svg").default;
    }
  }
}

import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faStar, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faFolderArrowDown,
  faFileCheck,
  faPeopleGroup,
  faPersonSkiLift,
  faRankingStar,
  faFootballHelmet,
  faPlaneCircleExclamation,
  faRingsWedding,
  faUtensilsSlash,
  faCircleDollar,
  fas,
  faHouseWater,
  faPersonSwimming,
  faCirclePlus,
  faVanShuttle,
  faBaby,
  faCompass,
  faSpinner,
  faAngleRight,
  faPlus,
  faCity,
  faPlane,
  faExchangeAlt,
  faTimes,
  faEllipsisH,
  faMobileAlt,
  faCaretDown,
  faMapMarkerAlt,
  faCheck,
  faRemoveFormat,
  faCalendar,
  faCalendarAlt,
  faClock,
  faHotel,
  faInfoCircle,
  faUniversalAccess,
  faClockRotateLeft,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";

import "./style.scss";
import {
  faEmptySet,
  faBath,
  faShower,
  faAirConditioner,
  faBlinds,
  faToothbrush,
  faVault,
  faWifi,
  faTv,
  faCoffeePot,
  faKitchenSet,
  faBedFront,
  faBottleWater,
  faPhoneOffice,
  faRefrigerator,
  faUmbrellaBeach,
  faBathtub,
  faBlind,
  faMartiniGlass,
  faLampDesk,
  fat
} from "@fortawesome/pro-thin-svg-icons";
import {
  faVolumeSlash,
  faPlateUtensils
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faPersonSwimming,
  faHouseWater,
  fas,
  fat,
  faLampDesk,
  faMartiniGlass,
  faBlind,
  faBathtub,
  faUmbrellaBeach,
  faBedFront,
  faClockRotateLeft,
  faVolumeSlash,
  faBath,
  faShower,
  faAirConditioner,
  faBlinds,
  faToothbrush,
  faVault,
  faWifi,
  faTv,
  faCoffeePot,
  faKitchenSet,
  faBedFront,
  faBottleWater,
  faPhoneOffice,
  faRefrigerator,
  faCircleDollar,
  faEmptySet,
  faPlateUtensils,
  faUtensilsSlash,
  faFileCheck,
  (faFolderArrowDown as IconDefinition),
  (faPeopleGroup as IconDefinition),
  (faPersonSkiLift as IconDefinition),
  (faRankingStar as IconDefinition),
  (faFootballHelmet as IconDefinition),
  (faPlaneCircleExclamation as IconDefinition),
  faRingsWedding,
  faCirclePlus,
  faVanShuttle,
  faBaby,
  faCompass,
  faStar,
  faSpinner,
  faPlus,
  faCity,
  faPlane,
  faExchangeAlt,
  faAngleRight,
  faTimes,
  faEllipsisH,
  faMobileAlt,
  faCaretDown,
  faTimesCircle,
  faMapMarkerAlt,
  faCheck,
  faRemoveFormat,
  faCalendar,
  faClock,
  faHotel,
  faInfoCircle,
  faUniversalAccess,
  faCalendarAlt,
  faExclamationTriangle
);
// tslint:disable-next-line:variable-name
const FontAwesomeIconAny = FontAwesomeIcon;
export default FontAwesomeIconAny;

// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";

import { IFilter, IResultProductPrice } from "./types/fly-result.types";
import { FlightSearchItemResource } from "tl-api-doc/typescript-generator";

const getSearchResults = (
  searchId: string,
  filter: Partial<IFilter>,
  locale: string
) => {
  return http.put(
    `/customer/search/flight/requests/${searchId}/filter/`,
    filter,
    {
      headers: {
        "Accept-Language": locale
      }
    }
  );
};
const getFareFamilies = (
  { productId }:
        { productId: string }
) => {
  return http.get<FlightSearchItemResource[]>(`customer/search/flight/fare_families/${productId}`);
};

const getSearchResultsPrice = (
  searchId: string,
  productIds: string | string[],
  sort?: string
) => {
  return http.get<IResultProductPrice[]>(
    `/customer/search/flight/requests/${searchId}/prices?${encodeURIComponent(
      "productIds[]"
    )}=${productIds}`,
    {
      pricePerProductLimit: 10,
      pricePerProductOffset: 0,
      sort
    }
  );
};

const getAgencyLogo = (agencyId: string, size = "") => {
  const query: string = size ? `${agencyId}?size=_${size}` : agencyId;
  return http.getImg(`/logo/${query}`, {
    responseType: "blob"
  });
};

const getProductRules = (productId: string) => {
  return http.get(
    `/customer/search/flight/products/${productId}/fare_rules`,
    {}
  );
};

export default {
  getFareFamilies,
  getAgencyLogo,
  getProductRules,
  getSearchResults,
  getSearchResultsPrice
};

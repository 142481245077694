import { Store, StoreOptions } from "vuex";

import HOTEL_LOCATION from "./hotel-location.name";
import { hotelLocationStore } from "./hotel-location.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(HOTEL_LOCATION, hotelLocationStore);
};

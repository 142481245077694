import moment from "moment";

import {
  IAdditional,
  IConvertToPackageResult,
  IConvertToPackageResultForHotels,
  ILastFilter,
  IMinMax,
  IPartOfList,
  IProductFilter,
  IProductFilterClass
} from "modules/product-result/data/product-result.types";
import { HotelSorting, IHotelPartOfList } from "modules/product-result/data/product-filter/product-filter.types";
import { HotelFacility, HotelFilter, StatPackageFilter, SupplierPriceResource } from "tl-api-doc/typescript-generator";
import { Vue } from "vue-property-decorator";

export default class ProductFilter implements IProductFilterClass {
  lastFilter: StatPackageFilter | HotelFilter = null;
  private _durationFormat = "[PT]H[H]m[M]s[S]";
  private _ourDepartureMinutes: number[] = [0, 1439];
  private _inDepartureMinutes: number[] = [0, 1439];
  private _ourArrivalMinutes: number[] = [0, 1439];
  private _inArrivalMinutes: number[] = [0, 1439];

  constructor(private readonly _filter: IProductFilter | ILastFilter) {
    if (_filter) {
      Vue.set(this, "_filter", _filter);
    }
    if (_filter.hotelFacilityIds) {
      Vue.set(this, "_facilities", _filter.hotelFacilityIds);
    }
    if (_filter.boarding) {
      Vue.set(this, "_boarding", _filter.boarding);
    }
    if (_filter.dealFeatures) {
      Vue.set(this, "_dealFeatures", _filter.dealFeatures);
    }
    if (_filter.lastFilter) {
      Vue.set(this, "lastFilter", _filter.lastFilter);
      this.providersNames = _filter.lastFilter.providers;
      this.areasNames = _filter.lastFilter.areaIds;
      this._hotelsNames = _filter.lastFilter.hotelNames;
      this._refundable =
                _filter.lastFilter.refundable === true ? true : _filter.lastFilter.refundable === false ? false : null;
    }
    if (_filter.hotelStars) {
      this._hotelsStars = this.getFilterConditionValue(_filter.hotelStars);
    }

    if (_filter.sort) {
      this._sorting = this.convertSortValueFilter(_filter.sort[0]);
    }

    // filter price values may be string like 100,14 and BE will throw exception when trying to parse it on applyFilter method
    _filter.price = _filter.price && _filter.price.max && _filter.price.min && Number(_filter.price.max) &&
        Number(_filter.price.min)
      ? {
        max: Math.trunc(Number(_filter.price.max)),
        min: Math.trunc(Number(_filter.price.min))
      }
      : {
        max: 99999999,
        min: 0
      };
  }

  private _sorting: HotelSorting = HotelSorting.priceDESC;

  get sorting(): HotelSorting {
    return this._sorting;
  }

  set sorting(value: HotelSorting) {
    this._sorting = value;
  }

  private _dealFeatures: string[] = [];

  get dealFeatures(): string[] {
    return this._filter.dealFeatures;
  }

  private _page = 0;

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

  get areas(): SupplierPriceResource[] {
    return this._filter.pricesTotal ? this._filter.pricesTotal.areas : [];
  }

  private _areasNames: string[] = [];

  get areasNames(): string[] {
    return this._areasNames;
  }

  set areasNames(value: string[]) {
    this._areasNames = value;
  }

  private _boarding: string[] = [];

  get boarding(): string[] {
    return this._filter.boarding;
  }

  set boarding(value: string[]) {
    this._boarding = value;
  }

  private _facilities: string[] = [];

  get facilities(): string[] {
    return this._facilities;
  }

  set facilities(value: string[]) {
    this._facilities = value;
  }

  private _hotelsNames: string[] = [];

  get hotelsNames(): string[] {
    return this._hotelsNames;
  }

  set hotelsNames(value: string[]) {
    this._hotelsNames = value;
  }

  private _hotelsStars: number[] = [0, 5];

  get hotelsStars(): number[] {
    return this._hotelsStars;
  }

  set hotelsStars(value: number[]) {
    this._hotelsStars = value;
  }

  private _providersNames: string[] = [];

  get providersNames(): string[] {
    return this._providersNames;
  }

  set providersNames(value: string[]) {
    this._providersNames = value;
  }

  get content(): IProductFilterClass["content"] {
    return this._filter;
  }

  get dealFeaturesCurrent(): string[] {
    return this._dealFeatures;
  }

  set dealFeaturesCurrent(val: string[]) {
    this._dealFeatures = val;
  }

  get totalPages(): number {
    return this._filter.totalPages;
  }

  get hotelFacilities(): IAdditional[] {
    return this._filter.hotelFacilities;
  }

  get price(): number[] {
    return this.getFilterConditionValue(this._filter.price);
  }

  set price(value: number[]) {
    this._filter.price = this.convertFilterConditionValue(value);
  }

  get hotels(): IHotelPartOfList[] {
    return this._filter.hotels;
  }

  set hotels(value: IHotelPartOfList[]) {
    Vue.set(this._filter, "hotels", value);
  }

  get rawFacilities(): HotelFacility[] {
    return this._filter.rawFacilities;
  }

  set rawFacilities(value: HotelFacility[]) {
    Vue.set(this._filter, "rawFacilities", value);
  }

  get providers(): IPartOfList[] {
    return this._filter.providers;
  }

  set providers(value: IPartOfList[]) {
    console.log("providers", value);
    Vue.set(this._filter, "providers", value);
  }

  get inFlightTime(): number[] {
    return this.getFilterDurationConditionValue(this._filter.inFlightTime);
  }

  set inFlightTime(value: number[]) {
    this._filter.inFlightTime = this.convertFilterDurationConditionValue(value);
  }

  get outFlightTime(): number[] {
    return this.getFilterDurationConditionValue(this._filter.outFlightTime);
  }

  set outFlightTime(value: number[]) {
    this._filter.outFlightTime = this.convertFilterDurationConditionValue(value);
  }

  get connectionTime(): number[] {
    return this.getFilterDurationConditionValue(this._filter.connectionTime);
  }

  set connectionTime(value: number[]) {
    this._filter.connectionTime = this.convertFilterDurationConditionValue(
      value
    );
  }

  get outDeparture(): number[] {
    return this._ourDepartureMinutes;
  }

  set outDeparture(value: number[]) {
    this._ourDepartureMinutes = value;
  }

  get inDeparture(): number[] {
    return this._inDepartureMinutes;
  }

  set inDeparture(value: number[]) {
    this._inDepartureMinutes = value;
  }

  get outArrival(): number[] {
    return this._ourArrivalMinutes;
  }

  set outArrival(value: number[]) {
    this._ourArrivalMinutes = value;
  }

  get inArrival(): number[] {
    return this._inArrivalMinutes;
  }

  set inArrival(value: number[]) {
    this._inArrivalMinutes = value;
  }

  private _refundable: boolean = null;

  get refundable(): boolean {
    return this._refundable;
  }

  set refundable(refundable: boolean) {
    this._refundable = refundable;
  }

  getFilterConditionValue(value: IMinMax<number>): number[] {
    return value && value.min && value.max
      ? [Number(value.min), Number(value.max)]
      : [0, 1439];
  }

  getFilterDurationConditionValue(value: IMinMax<string>): number[] {
    return value && value.min && value.max
      ? [moment.duration(value.min).asMinutes(), moment.duration(value.max).asMinutes()]
      : [0, 1439];
  }

  isEmptyConditionValue(conditionValue: number[]): boolean {
    return conditionValue[1] - conditionValue[0] === 0;
  }

  convertMinutesToHHmm(minutes: number): string {
    return moment
      .utc()
      .startOf("day")
      .add({ minutes })
      .format("HH:mm");
  }

  convertMinutesToDuration(minutes: number): string {
    return moment
      .utc(moment.duration(minutes, "minutes").asMilliseconds())
      .format(this._durationFormat);
  }

  convertMinutesToHM(minutes: number): string {
    return moment
      .utc(moment.duration(minutes, "minutes").asMilliseconds())
      .format("H[h] m[m]");
  }

  convertFilterConditionValue<T>(value: T[]): IMinMax<T> {
    return {
      max: value[1],
      min: value[0]
    };
  }

  convertSortValueFilter(value: { [key: string]: "ASC" | "DESC" }) {
    if (!value) {
      return HotelSorting.priceASC;
    }

    if ("PRICE" in value && value.PRICE === "ASC") {
      return HotelSorting.priceASC;
    }

    if ("PRICE" in value && value.PRICE === "DESC") {
      return HotelSorting.priceDESC;
    }

    if ("STARS" in value && value.STARS === "ASC") {
      return HotelSorting.starASC;
    }

    if ("STARS" in value && value.STARS === "DESC") {
      return HotelSorting.starDESC;
    }

    if ("DISTANCE_TO_CITY_CENTER" in value && value.DISTANCE_TO_CITY_CENTER === "ASC") {
      return HotelSorting.centerDistance;
    }
  }

  convertFilterSortValue(value: HotelSorting) {
    switch (value) {
    case HotelSorting.priceASC:
      return {
        PRICE: "ASC"
      };
    case HotelSorting.priceDESC:
      return {
        PRICE: "DESC"
      };
    case HotelSorting.starASC:
      return {
        STARS: "ASC"
      };
    case HotelSorting.starDESC:
      return {
        STARS: "DESC"
      };
    case HotelSorting.centerDistance:
      return {
        DISTANCE_TO_CITY_CENTER: "ASC"
      };
    }
  }

  convertFilterDurationConditionValue(value: number[]): IMinMax<string> {
    return {
      max: this.convertMinutesToDuration(value[1]),
      min: this.convertMinutesToDuration(value[0])
    };
  }

  convertFilterTimeConditionValue(value: number[]): IMinMax<string> {
    return {
      max: this.convertMinutesToHHmm(value[1]),
      min: this.convertMinutesToHHmm(value[0])
    };
  }

  convertFilterToPackageResult(): IConvertToPackageResult {
    return {
      boarding: this._boarding,
      connectionTime: this.convertFilterDurationConditionValue(
        this.connectionTime
      ),
      sort: [this.convertFilterSortValue(this._sorting)],
      dealFeatures: this.dealFeaturesCurrent,
      hotelFacilityIds: this._facilities,
      hotelNames: this._hotelsNames,
      hotelStars: this.convertFilterConditionValue(this._hotelsStars),
      inArrival: this.convertFilterTimeConditionValue(this.inArrival),
      inDeparture: this.convertFilterTimeConditionValue(this.inDeparture),
      inFlightTime: this.convertFilterDurationConditionValue(this.inFlightTime),
      outArrival: this.convertFilterTimeConditionValue(this.outArrival),
      outDeparture: this.convertFilterTimeConditionValue(this.outDeparture),
      outFlightTime: this.convertFilterDurationConditionValue(
        this.outFlightTime
      ),
      page: this.page,
      price: this.convertFilterConditionValue(this.price),
      providers: this._providersNames
    };
  }

  convertFilterToPackageResultForHotels(): IConvertToPackageResultForHotels {
    return {
      areaIds: this._areasNames,
      sort: [this.convertFilterSortValue(this._sorting)],
      boarding: this._boarding,
      providers: this._providersNames,
      hotelNames: this._hotelsNames,
      page: this.page,
      hotelStars: this.convertFilterConditionValue(this._hotelsStars),
      hotelFacilityIds: this._facilities,
      price: this.convertFilterConditionValue(this.price),
      refundable: this._refundable
    };
  }
}

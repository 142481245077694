import { GetterTree, Module } from "vuex";

import HotelDesktopConfig from "./types/hotel-app.config-class";
import { IDesktopStore } from "./types/hotel-app.types";
import { RootState } from "../store";

const state: IDesktopStore = {
  config: new HotelDesktopConfig()
};

const getters: GetterTree<IDesktopStore, RootState> = {
  config: (state: IDesktopStore) => state.config
};

export const hotelStore: Module<IDesktopStore, RootState> = {
  namespaced: true,
  getters,
  state
};

import * as Sentry from "@sentry/vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

import actionHelper from "utils/actionHelper";

import {
  HotelRequestPayload,
  ICity,
  IDestination,
  ILocation,
  IPackageQueryClass,
  IProductSearchState,
  IQuery,
  ISearchResponse,
  ISearchSegment,
  PackageQuery,
  QueryTypes
} from "modules/product-search/data/product-search.types";
import api from "modules/product-search/product-search.api";
import { AbstractRequestResource, HotelSearchQueryRoom } from "tl-api-doc/typescript-generator";
import { setCity } from "common/citiesHotel";
import _ from "lodash";
import { IPaxes } from "../fly-search/types/fly-search.types";
import FlySearchQuery from "../fly-search/types/fly-search-query.class";
import { RootState } from "../../applications/desktop/store";
import { IConvertedHotelQuery } from "./data/product-search.query/product-search.query.types";

const BEGIN_SEARCH = actionHelper("BEGIN_SEARCH");
const CREATE_QUEUE_CLASS = actionHelper("CREATE_QUEUE_CLASS");
const GET_DESTINATION_COUNTRIES = actionHelper("GET_DESTINATION_COUNTRIES");
const GET_DESTINATION_CITIES = actionHelper("GET_DESTINATION_CITIES");
const GET_DEPARTURE_DATES = actionHelper("GET_DEPARTURE_DATES");
const GET_RETURN_DATES = actionHelper("GET_RETURN_DATES");

// temp
const INITIAL_QUERY_BODY = "INITIAL_QUERY_BODY";
const CLEAR_RETURN_DATES = "CLEAR_RETURN_DATES";

const LOAD_QUERY = "LOAD_QUERY";
const namespaced = true;

const state: IProductSearchState = {
  availableDepDates: [],
  availableDestinations: [],
  availableRetDates: [],
  isCalendarLoadingPending: false,
  isError: false,
  isLoadingPending: false,
  queryBody: null,
  searchQuery: null
};

const getters: GetterTree<IProductSearchState, RootState> = {
  availableDepDates: (state: IProductSearchState) => state.availableDepDates,
  availableDestinations: (state: IProductSearchState) =>
    state.availableDestinations,
  availableRetDates: (state: IProductSearchState) => state.availableRetDates,
  isCalendarLoadingPending: (state: IProductSearchState) =>
    state.isCalendarLoadingPending,
  isError: (state: IProductSearchState) => state.isError,
  isLoadingPending: (state: IProductSearchState) => state.isLoadingPending,
  queryBody: (state: IProductSearchState) => state.queryBody,
  searchQuery: (state: IProductSearchState) => state.searchQuery
};

const actions: ActionTree<IProductSearchState,
    RootState> = {
      async getDepartureDates({ commit }, {
        cityCode,
        websiteKey
      }: { cityCode: string, websiteKey: string }) {
        try {
          commit(GET_DEPARTURE_DATES.INITIAL);
          commit(
            GET_DEPARTURE_DATES.SUCCEEDED,
            await api.getDepartureDates(websiteKey, cityCode)
          );
        } catch (error) {
          commit(GET_DEPARTURE_DATES.FAILED, error);
        }
      },
      async getReturnDates({ commit }, {
        websiteKey,
        cityCode,
        departureDate
      }: {
        websiteKey: string,
        cityCode: string,
        departureDate: string
    }) {
        try {
          commit(GET_RETURN_DATES.INITIAL);
          commit(
            GET_RETURN_DATES.SUCCEEDED,
            await api.getReturnDates(websiteKey, cityCode, departureDate)
          );
        } catch (error) {
          commit(GET_RETURN_DATES.FAILED, error);
        }
      },
      async getDestinationCountries({ commit }, {
        locale,
        websiteKey
      }: { websiteKey: string, locale?: string }) {
        try {
          commit(GET_DESTINATION_COUNTRIES.INITIAL);
          commit(
            GET_DESTINATION_COUNTRIES.SUCCEEDED,
            await api.getActiveDestinationCountries(websiteKey, locale)
          );
          return Promise.resolve();
        } catch (error) {
          commit(GET_DESTINATION_COUNTRIES.FAILED);
          return Promise.reject();
        }
      },
      async getDestinationCities(
        { commit },
        {
          countryCode,
          query,
          locale,
          websiteKey
        }: {
            websiteKey: string,
            countryCode?: string;
            query?: any,
            locale?: string
        }
      ) {
        try {
          commit(GET_DESTINATION_CITIES.INITIAL);
          commit(
            GET_DESTINATION_CITIES.SUCCEEDED,
            await api.getActiveDestinations(websiteKey, countryCode, query, locale)
          );
          return Promise.resolve();
        } catch (error) {
          commit(GET_DESTINATION_CITIES.FAILED, error);
          return Promise.reject();
        }
      },
      initialQuery(
        { commit },
        {
          queryType = QueryTypes.package,
          segments = null,
          paxes = null,
          rooms = null
        }: { queryType: QueryTypes; segments?: ISearchSegment[]; paxes?: IPaxes; rooms?: HotelSearchQueryRoom[] }
      ): void {
        try {
          let query: IPackageQueryClass = null;
          const defaultResidency = {
            entityId: "cbada7ce-cd49-3271-9ca3-91bd8dc465ed",
            name: "Israel",
            locale: "EN",
            code: "IL"
          };
          const queryBlank: IQuery = {
            rooms: [
              {
                adults: 2,
                childAges: []
              }
            ],
            residency: defaultResidency,
            paxes: { ADULT: 2 },
            segments: [
              {
                departureDate: null,
                fromAirport: null,
                toAirport: null
              },
              {
                departureDate: null,
                fromAirport: null,
                toAirport: null
              }
            ]
          };

          commit(CREATE_QUEUE_CLASS.INITIAL);

          const searchQueryKey = `${queryType.toLocaleLowerCase()}SearchQuery`;
          const localQuery: IQuery = JSON.parse(sessionStorage.getItem(searchQueryKey)) || queryBlank;
          if (segments) {
            const cloneQueryBlank = _.cloneDeep(queryBlank);
            cloneQueryBlank.rooms = rooms;
            cloneQueryBlank.paxes = paxes;
            cloneQueryBlank.segments = segments;
            query = new PackageQuery(cloneQueryBlank);
          } else {
            query = new PackageQuery(localQuery);
          }
          commit(CREATE_QUEUE_CLASS.SUCCEEDED, query);
        } catch (error) {
          commit(CREATE_QUEUE_CLASS.FAILED, error);
        }
      },
      async setQuery({ commit }, query: IPackageQueryClass) {
        commit(CREATE_QUEUE_CLASS.SUCCEEDED, query);
      },
      saveQuery({}, {
        query,
        queryType = QueryTypes.package
      }: { query: IQuery; queryType?: QueryTypes }) {
        sessionStorage.setItem(`${queryType.toLocaleLowerCase()}SearchQuery`, JSON.stringify(query));
      },
      async beginSearch(
        { commit },
        {
          typeQuery,
          query,
          aia,
          websiteKey
        }: {
            typeQuery: QueryTypes;
            query: IPackageQueryClass;
            aia: boolean;
            websiteKey: string
        }
      ): Promise<AbstractRequestResource> {
        commit(BEGIN_SEARCH.INITIAL);

        try {
          if (typeQuery === QueryTypes.flight) {
            const result = (await api.beginSearch(query.convertToRequest())).data;
            commit(BEGIN_SEARCH.SUCCEEDED, result);
            return result;
          } else if (typeQuery === QueryTypes.hotel) {
            const rawQuery: IConvertedHotelQuery = (query as PackageQuery).convertToRequest();
            console.log(rawQuery);
            const hotelRequestPayload: HotelRequestPayload = {
              websiteType: aia ? "AGENCY_TERMINAL" : "AGENCY_SHOWCASE",
              websiteKey,
              destinationCode: rawQuery.segments[0].toAirport,
              checkInDate: rawQuery.segments[0].departureDate,
              checkOutDate: rawQuery.segments[1].departureDate,
              rooms: rawQuery.rooms,
              residency: rawQuery.residency,
              includeHostels: true
            };
            const result = (await api.beginHotelSearch(hotelRequestPayload)).data;
            setCity({
              key: "cities",
              city: query.getFirstSegment().toAirport.city
            });
            commit(BEGIN_SEARCH.SUCCEEDED, result);
            return result;
          } else {
            const result = (await api.beginPackageSearch(query.convertToRequest(), aia, websiteKey)).data;
            commit(BEGIN_SEARCH.SUCCEEDED, result);
            return result;
          }
        } catch (error) {
          commit(BEGIN_SEARCH.FAILED, error);
        }
      },
      clearReturnDates({ commit }) {
        commit(CLEAR_RETURN_DATES);
      }
    };

const mutations: MutationTree<IProductSearchState> = {
  [GET_DESTINATION_COUNTRIES.INITIAL](state) {
    state.isCalendarLoadingPending = true;
  },
  [GET_DESTINATION_COUNTRIES.SUCCEEDED](state, countries) {
    const countriesSorted: ILocation[] = countries.data.sort((countryA: ILocation, countryB: ILocation) => {
      if (countryA.name < countryB.name) {
        return -1;
      }
      if (countryA.name > countryB.name) {
        return 1;
      }

      return 0;
    });
    state.availableDestinations = countriesSorted
      .map((country: ILocation) => ({
        country,
        cities: []
      }));
    state.isCalendarLoadingPending = false;
  },
  [GET_DESTINATION_COUNTRIES.FAILED](state, error) {
    state.isCalendarLoadingPending = false;
    // console.error(error)
  },
  [GET_DESTINATION_CITIES.INITIAL](state) {
    state.isCalendarLoadingPending = true;
  },
  [GET_DESTINATION_CITIES.SUCCEEDED](state, cities: { data: ICity[] }) {
    const citiesWithoutCountry = cities.data.filter(city => !city.country);
    if (citiesWithoutCountry.length) {
      const errorMessage = `Cities without country: ${JSON.stringify(citiesWithoutCountry)}`;
      const error = new Error(errorMessage);
      Sentry.captureException(error);
    }
    state.availableDestinations = state.availableDestinations.map((dest: IDestination) => {
      return {
        ...dest,
        cities: cities.data.filter((city: ICity) => city?.country?.code === dest.country.code)
      };
    });
    state.isCalendarLoadingPending = false;
  },
  [GET_DESTINATION_CITIES.FAILED](state, error) {
    state.isCalendarLoadingPending = false;
    // console.warn(error)
  },
  [GET_DEPARTURE_DATES.INITIAL](state) {
    state.isCalendarLoadingPending = true;
    state.availableDepDates = [];
  },
  [GET_DEPARTURE_DATES.SUCCEEDED](state, response) {
    state.availableDepDates = response.data;
    state.isCalendarLoadingPending = false;
  },
  [GET_DEPARTURE_DATES.FAILED](state, error) {
    state.isCalendarLoadingPending = false;
    // console.warn(error)
  },
  [GET_RETURN_DATES.INITIAL](state) {
    state.isCalendarLoadingPending = true;
    state.availableRetDates = [];
  },
  [GET_RETURN_DATES.SUCCEEDED](state, response) {
    state.availableRetDates = response.data;
    state.isCalendarLoadingPending = false;
  },
  [GET_RETURN_DATES.FAILED](state, error) {
    state.isCalendarLoadingPending = false;
    // console.warn(error)
  },
  [BEGIN_SEARCH.INITIAL](state) {
    // console.log('initial search process...')
    state.isLoadingPending = true;
  },
  [BEGIN_SEARCH.SUCCEEDED](state, searchData: ISearchResponse) {
    state.searchQuery?.entityId = searchData?.requestId;
    state.isLoadingPending = false;
  },
  [BEGIN_SEARCH.FAILED](state, error) {
    state.isLoadingPending = false;
    throw new Error(error);
  },
  [CREATE_QUEUE_CLASS.INITIAL](state) {
    // console.log('initial creating process...')
  },
  [CREATE_QUEUE_CLASS.SUCCEEDED](state, query: IPackageQueryClass) {
    state.searchQuery = query;
  },
  [LOAD_QUERY](state, query: IPackageQueryClass) {
    (Object.keys(state.searchQuery) as Array<keyof FlySearchQuery>)
      .forEach((key) => {
        // @ts-ignore
        const value = query[key];
        if (value) {
          // @ts-ignore
          state.searchQuery[key] = _.cloneDeep(value);
        }
      });
  },
  [CREATE_QUEUE_CLASS.FAILED](state, error) {
    throw new Error(error);
  },
  [INITIAL_QUERY_BODY](state, queryBodyData) {
    state.queryBody = new PackageQuery(queryBodyData);
  },
  [CLEAR_RETURN_DATES](state: IProductSearchState) {
    state.availableRetDates = [];
  }
};

export const productSearchStore: Module<IProductSearchState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};

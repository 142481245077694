import { Store } from "vuex";
import createApi from "./fly-order.api";
import FLY_ORDER_PRODUCT from "./fly-order.name";
import createStore from "./fly-order.store";
import Http from "common/http/createHttp";

export default (context: { store: Store<any>; http: typeof Http }): void => {
  const { store, http } = context;
  const api = createApi({ http });

  store.registerModule(FLY_ORDER_PRODUCT, createStore({ api }), {
    preserveState: store.state.FLY_ORDER_PRODUCT
  });
};

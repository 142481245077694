import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

import api from "./hotel-location.api";
import { City } from "./types/hotel-location.class";
import { IHotelCity, IHotelLocationRequest, IHotelLocationState } from "./types/hotel-location.types";

import actionHelper from "utils/actionHelper";
import { isLocalStorageCities } from "../../common/citiesHotel";
import { getLocalStorage } from "../../common/localStorageHelper";
import { RootState } from "applications/desktop/store";

const FIND_CITIES_PREDEFINED = actionHelper("FIND_CITIES_PREDEFINED");
const FIND_CITIES = actionHelper("FIND_CITIES");

const namespaced = true;

const state: IHotelLocationState = {
  cities: [new City()],
  isError: false,
  isLoadingPending: false
};

const getters: GetterTree<IHotelLocationState, RootState> = {
  cities: (state: IHotelLocationState): City[] => state.cities,
  isLoadingPending: (state: IHotelLocationState): boolean =>
    state.isLoadingPending
};

const actions: ActionTree<IHotelLocationState,
    RootState> = {
      async findCities({ commit }: ActionContext<IHotelLocationState, RootState>, payload: IHotelLocationRequest) {
        commit(FIND_CITIES.INITIAL);

        const { locale, ...queryParams } = payload;
        try {
          commit(FIND_CITIES.SUCCEEDED, {
            cities: (await api.findCities(queryParams, locale)).data
          });
        } catch (e) {
          commit(FIND_CITIES.FAILED, e);
        }
      },

      async getPredefinedCities(
        { state, commit }: ActionContext<IHotelLocationState, RootState>,
        locale: string
      ) {
        commit(FIND_CITIES_PREDEFINED.INITIAL);

        const cities: IHotelCity[] = isLocalStorageCities({ key: "cities" })
          ? getLocalStorage({ key: "cities" })
          : (await api.findCities(null, locale)).data;

        try {
          setTimeout(() => commit(FIND_CITIES_PREDEFINED.SUCCEEDED, { cities }), 0);
        } catch (e) {
          commit(FIND_CITIES_PREDEFINED.FAILED, e);
        }
        return cities;
      }
    };

const mutations: MutationTree<IHotelLocationState> = {
  [FIND_CITIES_PREDEFINED.INITIAL](state) {
    state.isLoadingPending = true;
  },
  [FIND_CITIES_PREDEFINED.SUCCEEDED](state, { cities }) {
    state.cities = cities;
    state.isLoadingPending = false;
  },
  [FIND_CITIES_PREDEFINED.FAILED](error) {
    // console.log("FIND_CITIES_PREDEFINED.FAILED", error)
  },
  [FIND_CITIES.INITIAL](state) {
    state.isLoadingPending = true;
  },
  [FIND_CITIES.SUCCEEDED](state, { cities }) {
    state.cities = cities;
    state.isLoadingPending = false;
  },
  [FIND_CITIES.FAILED](error) {
    state.isLoadingPending = false;
    console.error("FIND_CITIES.FAILED", error);
  }
};

export const hotelLocationStore: Module<IHotelLocationState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};

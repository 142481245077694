import { Store } from "vuex";

import I18N_MODULE from "./i18n.name";
import { i18nStore } from "./i18n.store";

export default (context: { store: Store<any>; http: any }): void => {
  const { store } = context;

  store.registerModule(I18N_MODULE, i18nStore, {
    preserveState: store.state.i18n_MODULE
  });
  store.dispatch(`${I18N_MODULE}/setDefaultLocale`);
  store.dispatch(`${I18N_MODULE}/applyAxiosHeaderLocale`);
};

// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";
import { SupplierConfigResource, WebsiteResource, WebsiteSessionCreate } from "tl-api-doc/typescript-generator";

const getSiteByKey = (websiteKey: string) => {
  return http.get<WebsiteResource>(`/customer/websites/${websiteKey}`, {});
};

// stub login
// POST /customer/stub/auth/session/start
// IN: {@link WebsiteSessionCreate}
//
// OUT: Session UUID

const stubLogin = (payload: WebsiteSessionCreate) => {
  return http.post<string>("/customer/stub/auth/session/start", payload);
};

const getOwnCompany = (uuid: string) => {
  return http.get("/mycompany", { uuid });
};
const getOwnUser = (uuid: string, websiteKey: string = null) => {
  return http.get("/mycompany/me", { uuid });
};

const getConfigs = ({
  filter,
  uuid
}: {
    filter: any
    uuid: string
}) => {
  return http.put<{ content: SupplierConfigResource[] }>(`mycompany/configs?uuid=${uuid}`, {
    ...filter,
    pageable: {
      sort: [
        {
          createTs: "ASC"
        }
      ],
      offset: 0,
      pageNumber: 0,
      pageSize: 20
    }
  });
};
const getEnabledProductTypes = (websiteKey: string) => {
  const headers = {
    Accept: "application/hal+json"
  };
  return http.get(`/customer/settings/websites/${websiteKey}/enabled/productTypes`, {}, headers);
};

export default {
  stubLogin,
  getConfigs,
  getOwnUser,
  getOwnCompany,
  getSiteByKey,
  getEnabledProductTypes
};

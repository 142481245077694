import {
  IPackage,
  IPackageClass,
  IPackagesListClass,
  Package
} from "modules/product-result/data/product-result.types";

export default class PackagesList {
  private _packagesList: IPackageClass[];
  // private _productsIds: Array<IPackageProductsList['productId']> = []

  constructor(private _packagesListData: IPackage[]) {
    this._packagesList = this._packagesListData.map(
      packageData => new Package(packageData)
    );
  }

  removeBadPackage(packageId: string) {
    this._packagesList = this._packagesList.filter(pack => pack.hotel.content.id !== packageId);
  }

  get content(): IPackagesListClass["content"] {
    return this._packagesList;
  }

  concatPackages(packagesListData: IPackage[]): void {
    const newList = packagesListData.map(
      packageData => new Package(packageData)
    );
    this._packagesList = this._packagesList.concat(newList);
  }

  getPackage(packageId: string): IPackageClass {
    return (
      this._packagesList.find(
        packageData => packageData.hotel.content.id === packageId
      ) || null
    );
  }
}

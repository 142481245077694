// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";

import { IConvertedQuery, IProductFilter } from "modules/product-result/data/product-result.types";
import { HotelFacility, HotelSupplierPriceResource, SupplierPriceResource } from "tl-api-doc/typescript-generator";
import { ApiBaseResource } from "../../common/types/types";

const beginSearch = (query: IConvertedQuery) => {
  return http.post("/customer/search/flight/requests", { ...query });
};

const applyResultFilter = (requestId: string, filter: IProductFilter) => {
  return http.put<any>(
    `/customer/search/statpackage/requests/${requestId}/filter`,
    {
      ...filter
    }
  );
};

const applyResultFilterForHotels = (
  requestId: string,
  filter: IProductFilter
) => {
  return http.put<any>(`/customer/search/hotels/requests/${requestId}/filter`, {
    ...filter
  });
};

const groupResultByHotel = (
  requestId: string,
  isExclude = false,
  filter?: any
) => {
  if (isExclude) {
    /// customer/search/hotels/requests/{requestId}/intermediate/hotels/filter
    return http.put(`/customer/search/statpackage/requests/${requestId}/intermediate/hotels/filter`, {
      ...filter
    });
  } else {
    /// customer/search/hotels/requests/{requestId}/compact/hotels/filter
    return http.put(`/customer/search/statpackage/requests/${requestId}/compact/hotels/filter`, {
      ...filter
    });
  }

  const excludePaths: string[] = [
    "hotelFacilities",
    "pricesTotal"
  ];
  const excludeQueries: string = isExclude
    ? `?${encodeURI("excludePaths[]")}=${excludePaths}`
    : "";
  const url = `/customer/search/statpackage/requests/${requestId}/hotels/filter${excludeQueries}`;
  return http.put(url, {
    ...filter
  });
};

// get HotelSupplierPriceResource[]
const getHotelSupplierPrices = (requestId: string, filter?: any) => {
  return http.put<ApiBaseResource<HotelSupplierPriceResource>>(
    `/customer/search/hotels/requests/${requestId}/digest/hotels`,
    {
      ...filter,
      page: 0, // Всегда хотим первую страницу и максимум результатов
      size: 10000
    }
  );
};

// get HotelSupplierPriceResource[]
const getPackageSupplierPrices = (requestId: string, filter?: any) => {
  return http.put<ApiBaseResource<HotelSupplierPriceResource>>(
    `/customer/search/statpackage/requests/${requestId}/digest/hotels`,
    {
      ...filter,
      page: 0, // Всегда хотим первую страницу и максимум результатов
      size: 10000
    }
  );
};

// digest/providers
const getHotelSupplierPricesProviders = (requestId: string, filter?: any) => {
  return http.put<ApiBaseResource<SupplierPriceResource>>(
    `/customer/search/hotels/requests/${requestId}/digest/providers`,
    {
      ...filter,
      page: 0, // Всегда хотим первую страницу и максимум результатов
      size: 10000
    }
  );
};

// digest/providers
const getPackageSupplierPricesProviders = (requestId: string, filter?: any) => {
  return http.put<ApiBaseResource<SupplierPriceResource>>(
    `/customer/search/statpackage/requests/${requestId}/digest/providers`,
    {
      ...filter,
      page: 0, // Всегда хотим первую страницу и максимум результатов
      size: 10000
    }
  );
};

// digest/facilities
const getPackageFacilities = (requestId: string, filter?: any) => {
  return http.put<ApiBaseResource<HotelFacility>>(
    `/customer/search/statpackage/requests/${requestId}/digest/facilities`,
    {
      ...filter,
      page: 0, // Всегда хотим первую страницу и максимум результатов
      size: 10000
    }
  );
};

const getHotelById = (hotelId: string) => {
// /customer/search/hotels/{hotelId}/info
  return http.get(`/customer/search/hotels/${hotelId}/info`);
};

const getPackageProducts = (requestId: string, productIds: string) => {
  console.log("getPackageProducts", requestId, productIds);
  return http.put(
    `/customer/search/statpackage/requests/${requestId}/products`, {
      itemIds: productIds
    });
};

const getPackageHotelById = (hotelId: string) => {
// /customer/search/hotels/{hotelId}/info
  return http.get(`/customer/search/statpackage/dictionary/${hotelId}/info`);
};

const groupResultByHotelForHotels = (
  requestId: string,
  isExclude = false,
  filter?: any
) => {
  if (isExclude) {
    /// customer/search/hotels/requests/{requestId}/intermediate/hotels/filter
    return http.put(`/customer/search/hotels/requests/${requestId}/intermediate/hotels/filter`, {
      ...filter
    });
  } else {
    /// customer/search/hotels/requests/{requestId}/compact/hotels/filter
    return http.put(`/customer/search/hotels/requests/${requestId}/compact/hotels/filter`, {
      ...filter
    });
  }

  const url = `/customer/search/hotels/requests/${requestId}/hotels/filter`;
  return http.put(url, {
    ...filter
  });
};

const getAgencyPrices = (requestId: string, productIds: string[]) => {
  return http.get(
    `/customer/search/statpackage/requests/${requestId}/prices?${encodeURIComponent(
      "productIds[]"
    )}=${productIds}`,
    {
      pricePerProductLimit: 10,
      pricePerProductOffset: 0
    }
  );
};

const getAgencyPricesForHotels = (requestId: string, productIds: string[]) => {
  return http.get(
    `/customer/search/hotels/requests/${requestId}/prices?${encodeURIComponent(
      "productIds[]"
    )}=${productIds}`,
    {
      pricePerProductLimit: 10,
      pricePerProductOffset: 0
    }
  );
};

const getHotelReviews = (hotelIds: string[]) => {
  return http.get(
    `/customer/search/hotels/reviews?${encodeURIComponent(
      "hotelIds[]"
    )}=${hotelIds}`,
    {}
  );
};

const getProductDetails = (productId: string, filter?: any) => {
  return http.get(
    `/customer/search/statpackage/products/${productId}/details`,
    {
      ...filter
    }
  );
};

const getProductDetailsForHotels = (productId: string, filter?: any) => {
  return http.get(`/customer/search/hotels/products/${productId}/details`, {
    ...filter
  });
};

const getPaxComplectAgencyPrices = ({
  productId,
  agencyId,
  complectIds,
  filter
}: {
    productId: string;
    agencyId: string;
    complectIds: string[];
    filter?: any;
}) => {
  return http.get(
    `/customer/search/statpackage/products/${productId}/details/prices?agencyId=${agencyId}&${encodeURIComponent(
      "complectIds[]"
    )}=${complectIds}`,
    {}
  );
};

const getPaxComplectAgencyPricesForHotels = ({
  productId,
  agencyId,
  complectIds,
  filter
}: {
    productId: string;
    agencyId: string;
    complectIds: string[];
    filter?: any;
}) => {
  return http.get(
    `/customer/search/hotels/products/${productId}/details/prices?agencyId=${agencyId}&${encodeURIComponent(
      "complectIds[]"
    )}=${complectIds}`,
    {}
  );
};

const setFacilitiesWithoutIcons = ({ icons }: { icons: string }) =>
  http.postPdf("/pdf/facilities/icons/without", { icons });

export default {
  getHotelSupplierPrices,
  getPackageSupplierPrices,
  getHotelById,
  getPackageHotelById,
  getPackageFacilities,
  applyResultFilter,
  applyResultFilterForHotels,
  beginSearch,
  getAgencyPrices,
  getAgencyPricesForHotels,
  getHotelReviews,
  getPaxComplectAgencyPrices,
  getHotelSupplierPricesProviders,
  getPaxComplectAgencyPricesForHotels,
  getProductDetails,
  getProductDetailsForHotels,
  getPackageSupplierPricesProviders,
  getPackageProducts,
  groupResultByHotel,
  groupResultByHotelForHotels,
  setFacilitiesWithoutIcons
};

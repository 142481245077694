import { Store } from "vuex";

import TABS_MODULE from "modules/tabs/tabs.name";
import createStore from "modules/tabs/tabs.store";
import tabsApi from "./tabs.api";
import Http from "../../common/http/createHttp";

export default (context: { store: Store<null>; http: typeof Http }): void => {
  const { store, http } = context;
  const api = tabsApi({ http });

  store.registerModule(TABS_MODULE, createStore({ api }));
};

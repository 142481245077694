
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { ICompany, ICompanyUser } from "modules/root/data/root.types";

import ROOT_MODULE from "modules/root/root.name";

import Popup from "ui-modules/popups/popup.vue";
import TlIcon from "ui-modules/icons/icon";
import { WebsiteResource } from "tl-api-doc/typescript-generator";

@Component({
  components: {
    Popup,
    TlIcon
  }
})
export default class RootError extends Vue {
  @Getter(`${ROOT_MODULE}/company`)
    company: ICompany;

  @Getter(`${ROOT_MODULE}/site`)
    site!: WebsiteResource;

  @Getter(`${ROOT_MODULE}/ownUser`)
    ownUser: ICompanyUser;

  @Action(`${ROOT_MODULE}/setIsErrorDialog`)
    setIsErrorDialog: (isErrorDialog: boolean) => void;

  get agentFirstName(): string {
    return this.ownUser?.firstName || null;
  }

  get midofficePhoneNumber(): string {
    return this.company?.parentCompany?.phone || null;
  }

  get midofficeEmail(): string {
    return this.company?.parentCompany?.email || null;
  }

  closeDialog(): void {
    this.setIsErrorDialog(false);
  }
}

import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

import actionHelper from "utils/actionHelper";
import api from "./product-order.api";

import {
  IAgencyLogo,
  IBeginOrderResponse,
  ICustomer,
  ILocation,
  IPackageOrderState,
  IPackageProductPrice,
  TRoomsAdditions
} from "./data/product-order.types";
import Vue from "vue";
import { QueryTypes } from "modules/product-search/data/product-search.consts";
import { AxiosPromise, AxiosResponse } from "axios/index";
import { IPaxTypeInformation } from "../fly-order/types/fly-order.types";
import { CustomerResource, TravelOrderResource } from "tl-api-doc/typescript-generator";
import { RootState } from "applications/desktop/store";
import _ from "lodash";

const BEGIN_ORDER = actionHelper("BEGIN_ORDER");
const PRODUCT_TO_ORDER = actionHelper("PRODUCT_TO_ORDER");
const FIND_COUNTRY = actionHelper("FIND_COUNTRY");
const REGISTRATION_NEW_CUSTOMER = actionHelper("REGISTRATION_NEW_CUSTOMER");
const REGISTRATION_OWNER = actionHelper("REGISTRATION_OWNER");
const ADD_CUSTOMER_TO_ORDER_ITEM = actionHelper("ADD_CUSTOMER_TO_ORDER_ITEM");
const START_ORDER_RESERVATION = actionHelper("START_ORDER_RESERVATION");
const GET_ORDER_PRERESERVATION_STATUS = actionHelper(
  "GET_ORDER_PRERESERVATION_STATUS"
);
const SET_CONTACT_PERSON = actionHelper("SET_CONTACT_PERSON");
const GET_RESERVATION_STATUS = actionHelper("GET_RESERVATION_STATUS");
const GET_PRODUCT_PRICE = actionHelper("GET_PRODUCT_PRICE");
const GET_AGENCY_LOGO = actionHelper("GET_AGENCY_LOGO");
const GET_ORDER_ITEMS = actionHelper("GET_ORDER_ITEMS");
const ADD_ADDITIONAL_PAYMENTS_TO_CUSTOMER = actionHelper(
  "ADD_ADDITIONAL_PAYMENTS_TO_CUSTOMER"
);
const GET_DICTIONARY_BOARDING = actionHelper("GET_DICTIONARY_BOARDING");
const GET_CONFIRMATION_STATUS = actionHelper("GET_CONFIRMATION_STATUS");
const SET_MARKUP_TO_ORDER = actionHelper("SET_MARKUP_TO_ORDER");
const GET_PAX_TYPE = actionHelper("GET_PAX_TYPE");
const GET_PAX_TYPE_BY_COMPLECT_ID = actionHelper("GET_PAX_TYPE_BY_COMPLECT_ID");
const SET_ORDER = actionHelper("SET_ORDER");

const DELETE_ORDER_ITEM_FROM_ORDER = actionHelper("DELETE_ORDER_ITEM_FROM_ORDER");

const SET_ORDER_ERROR = "SET_ORDER_ERROR";
const SET_ORDER_PENDING = "SET_ORDER_PENDING";
const INPUT_CUSTOMER = "INPUT_CUSTOMER";
const INPUT_CUSTOMER_INDEXES = "INPUT_CUSTOMER_INDEXES";
const INPUT_ALL_CUSTOMERS = "INPUT_ALL_CUSTOMERS";
const INPUT_OWNER = "INPUT_OWNER";
const INPUT_ASSISTANCE_PENDING = "INPUT_ASSISTANCE_PENDING";
const SET_ORDER_ITEM = "SET_ORDER_ITEM";
const SET_ADDITIONAL_PAYMENTS = "SET_ADDITIONAL_PAYMENTS";
const GENERATE_ADDITIONAL_PAYMENTS = "GENERATE_ADDITIONAL_PAYMENTS";
const SET_MARKUP_PRICE = "SET_MARKUP_PRICE";
const CLEAR_ITEM_IDS = "CLEAR_ITEM_IDS";
const SET_ITEM_IDS = "SET_ITEM_IDS";
const SET_IS_LAST_STEP_BOOKING = "SET_IS_LAST_STEP_BOOKING";
const SET_ORDER_ID = "SET_ORDER_ID";
const namespaced = true;

const state: IPackageOrderState = {
  conformationStatusPending: false,
  conformationStatus: null,
  conformationError: null,
  agencyLogoPending: true,
  agencyLogoUrl: null,
  bookedProduct: null,
  bookedProductPrice: null,
  countries: [],
  customers: [],
  error: null,
  isAssistancePending: false,
  isCountryPending: false,
  isLoadingPending: false,
  isOrderPending: false,
  isPricePending: false,
  itemIds: {},
  order: null,
  orderId: null,
  orderItems: [],
  orderNumber: null,
  orderPrereservationStatus: null,
  orderStatus: null,
  owner: [],
  typesOfAssistance: {
    assistances: [],
    meals: []
  },
  additions: null,
  dictionaryBoarding: [],
  markupPrice: null,
  customerDOBrule: null,
  customerDOBrules: null,
  isLastStepBooking: false
};

const getters: GetterTree<IPackageOrderState, RootState> = {
  customerDOBrule: (state: IPackageOrderState) => state.customerDOBrule,
  customerDOBrules: (state: IPackageOrderState) => state.customerDOBrules,
  agencyLogoPending: (state: IPackageOrderState) => state.agencyLogoPending,
  agencyLogoUrl: (state: IPackageOrderState) => state.agencyLogoUrl,
  bookedProduct: (state: IPackageOrderState) => state.bookedProduct,
  bookedProductPrice: (state: IPackageOrderState) => state.bookedProductPrice,
  countries: (state: IPackageOrderState) => state.countries,
  customers: (state: IPackageOrderState) => state.customers,
  error: (state: IPackageOrderState) => state.error,
  isAssistancePending: (state: IPackageOrderState) => state.isAssistancePending,
  isCountryPending: (state: IPackageOrderState) => state.isCountryPending,
  isOrderPending: (state: IPackageOrderState) => state.isOrderPending,
  isPricePending: (state: IPackageOrderState) => state.isPricePending,
  itemIds: (state: IPackageOrderState) => state.itemIds,
  order: (state: IPackageOrderState) => state.order,
  orderId: (state: IPackageOrderState) => state.orderId,
  orderItems: (state: IPackageOrderState) => state.orderItems,
  orderNumber: (state: IPackageOrderState) => state.orderNumber,
  orderPrereservationStatus: (state: IPackageOrderState) =>
    state.orderPrereservationStatus,
  orderStatus: (state: IPackageOrderState) => state.orderStatus,
  owner: (state: IPackageOrderState) => state.owner,
  typesOfAssistance: (state: IPackageOrderState) => state.typesOfAssistance,
  additions: (state: IPackageOrderState) => state.additions,
  dictionaryBoarding: (state: IPackageOrderState) => state.dictionaryBoarding,
  markupPrice: (state: IPackageOrderState) => state.markupPrice,
  isLastStepBooking: (state: IPackageOrderState) => state.isLastStepBooking
};

const actions: ActionTree<IPackageOrderState,
    RootState> = {
      async getPaxType(
        { commit },
        {
          itemId,
          birthday,
          serviceType
        }: {
            itemId: string;
            birthday: string;
            serviceType: "HOTEL" | "STATIC_PACKAGE";
        }
      ) {
        try {
          const getPaxType: AxiosResponse<IPaxTypeInformation> =
                await api.getPaxType({
                  itemId,
                  birthday,
                  serviceType
                });
          commit(GET_PAX_TYPE.SUCCEEDED, getPaxType.data);
          return Promise.resolve();
        } catch (e) {
          commit(GET_PAX_TYPE.FAILED, e);
          return Promise.reject();
        }
      },
      async getPaxTypeByComplectIds(
        { commit },
        {
          paxComplectIds,
          itemId,
          birthday
        }: {
            paxComplectIds: string[];
            itemId: string;
            birthday: string;
        }
      ) {
        commit(GET_PAX_TYPE_BY_COMPLECT_ID.INITIAL);
        try {
          const promises = paxComplectIds.map(paxComplectId => api.getPaxTypeByComplectId({
            paxComplectId,
            itemId,
            birthday
          }));
          const getPaxType: any = (await Promise.all(promises)).map(axiosResponse => axiosResponse.data);
          commit(GET_PAX_TYPE_BY_COMPLECT_ID.SUCCEEDED, getPaxType);
          return Promise.resolve();
        } catch (e) {
          commit(GET_PAX_TYPE_BY_COMPLECT_ID.FAILED, e);
          return Promise.reject();
        }
      },
      async getDictionaryBoarding({ commit }) {
        try {
          const dictionaryBoardingData = await api.getDictionaryBoarding();
          commit(GET_DICTIONARY_BOARDING.SUCCEEDED, dictionaryBoardingData);
        } catch (e) {
          commit(GET_DICTIONARY_BOARDING.FAILED, e);
        }
      },
      async addAdditionalPaymentsToCustomer(
        { commit },
        {
          orderId,
          itemId,
          customerId,
          additionalPayment,
          queryType = QueryTypes.package
        }: {
            orderId: string;
            itemId: string;
            customerId: string;
            additionalPayment: FormData;
            queryType: QueryTypes;
        }
      ) {
        try {
          const fn =
                queryType === QueryTypes.hotel
                  ? api.addBaggageToCustomerForHotels
                  : api.addBaggageToCustomer;
            // const result = await api.addBaggageToCustomer(orderId, itemId, customerId, additionalPayment)
          const result = await fn(orderId, itemId, customerId, additionalPayment);
          commit(ADD_ADDITIONAL_PAYMENTS_TO_CUSTOMER.SUCCEEDED, result);
        } catch (error) {
          commit(ADD_ADDITIONAL_PAYMENTS_TO_CUSTOMER.FAILED, error);
        }
      },
      async beginOrder(
        { commit },
        {
          productPrice,
          uuid
        }: {
            productPrice: IPackageProductPrice;
            uuid: string
        }
      ) {
        try {
          const orderId = await api.beginOrder({
            agencyId: productPrice.agency.agencyId,
            uuid
          });
          commit(BEGIN_ORDER.SUCCEEDED, orderId.data);

          return orderId.data.entityId;
        } catch (error) {
          commit(BEGIN_ORDER.FAILED, error);
        }
      },
      async deleteOrderItemFromOrder({ commit }: ActionContext<IPackageOrderState, RootState>, {
        orderId,
        itemId,
        uuid
      }: {
        orderId: string,
        itemId: string,
        uuid: string
    }) {
        commit(DELETE_ORDER_ITEM_FROM_ORDER.INITIAL);
        try {
          const result = await api.deleteOrderItemFromOrder(orderId, itemId, uuid);
          commit(DELETE_ORDER_ITEM_FROM_ORDER.SUCCEEDED, result);
          return result;
        } catch (error) {
          commit(DELETE_ORDER_ITEM_FROM_ORDER.FAILED, error);
        }
      },
      async addProductToOrder(
        { commit },
        {
          orderId,
          productId,
          complectIds,
          queryType = QueryTypes.package
        }: {
            orderId: string;
            productId: string;
            complectIds: string[];
            queryType: QueryTypes;
        }
      ) {
        try {
          const fn =
                queryType === QueryTypes.hotel
                  ? api.addProductToOrderForHotels
                  : api.addProductToOrder;
          commit(PRODUCT_TO_ORDER.SUCCEEDED, {
            complectIds,
            response: await fn(orderId, productId, complectIds)
          });
          // commit(PRODUCT_TO_ORDER.SUCCEEDED, {
          //   complectIds,
          //   response: await api.addProductToOrder(orderId, productId, complectIds)
          // })
        } catch (error) {
          commit(PRODUCT_TO_ORDER.FAILED, error);
        }
      },
      async getAgencyLogo({ commit }, params: IAgencyLogo) {
        try {
          commit(
            GET_AGENCY_LOGO.SUCCEEDED,
            await api.getAgencyLogo(params.agencyId, params.size)
          );
        } catch (e) {
          commit(GET_AGENCY_LOGO.FAILED, e);
        }
      },
      async registrationOwner(
        { commit },
        {
          owner,
          orderId
        }: {
            owner: ICustomer;
            orderId: string;
        }
      ) {
        try {
          const result: AxiosResponse<CustomerResource> =
                await api.registrationNewCustomer(orderId, owner);
          commit(REGISTRATION_OWNER.SUCCEEDED, result);
          return result.data;
        } catch (error) {
          commit(REGISTRATION_OWNER.FAILED, error);
        }
      },
      async registrationNewCustomer(
        { commit },
        {
          orderId,
          index,
          customer,
          isOwner
        }: {
            orderId: string;
            index: number;
            customer: ICustomer;
            isOwner: boolean;
        }
      ) {
        try {
          const result: AxiosResponse<CustomerResource> =
                await api.registrationNewCustomer(orderId, customer);
          commit(REGISTRATION_NEW_CUSTOMER.SUCCEEDED, {
            index,
            result,
            isOwner
          });
          return result.data;
        } catch (error) {
          commit(REGISTRATION_NEW_CUSTOMER.FAILED, error);
        }
      },
      async setContactPerson(
        { commit },
        {
          orderId,
          customerId
        }: {
            orderId: string;
            customerId: string
        }
      ) {
        try {
          commit(
            SET_CONTACT_PERSON.SUCCEEDED,
            await api.setContactPerson(orderId, customerId)
          );
        } catch (error) {
          commit(SET_CONTACT_PERSON.FAILED, error);
        }
      },
      async addCustomerToOrderItem(
        { commit },
        {
          orderId,
          itemId,
          customerId
        }: {
            orderId: string;
            itemId: string;
            customerId: string
        }
      ) {
        try {
          commit(
            ADD_CUSTOMER_TO_ORDER_ITEM.SUCCEEDED,
            await api.addCustomerToOrderItem(orderId, itemId, customerId)
          );
        } catch (error) {
          commit(ADD_CUSTOMER_TO_ORDER_ITEM.FAILED, error);
        }
      },
      async addCustomerToOrderItemNew(
        { commit },
        {
          orderId,
          itemIds,
          customerId,
          roomKey,
          paxPriceDetailId
        }: {
            orderId: string;
            itemIds: string[];
            customerId: string;
            roomKey: string;
            paxPriceDetailId?: string;
        }
      ) {
        try {
          commit(
            ADD_CUSTOMER_TO_ORDER_ITEM.SUCCEEDED,
            await api.addCustomerToOrderItemNew(
              orderId,
              itemIds,
              customerId,
              roomKey,
              paxPriceDetailId
            )
          );
          return Promise.resolve();
        } catch (error) {
          commit(ADD_CUSTOMER_TO_ORDER_ITEM.FAILED, error);
          return Promise.reject(error);
        }
      },
      async getOrderPreReservationStatus({ commit }, orderId: string) {
        try {
          if (!orderId) return;
          commit(
            GET_ORDER_PRERESERVATION_STATUS.SUCCEEDED,
            await api.startOrderPreReservation(orderId)
          );
        } catch (error) {
          commit(GET_ORDER_PRERESERVATION_STATUS.FAILED, error);
        }
      },
      async getConfirmationStatus({ commit }, orderId) {
        commit(GET_CONFIRMATION_STATUS.INITIAL);
        try {
          const confStatus = (
            await (api.getConfirmationStatus(orderId) as AxiosPromise<{
                    status: "OK" | "AWAIT" | "ERROR";
                }>)
          ).data;
          commit(GET_CONFIRMATION_STATUS.SUCCEEDED, confStatus);
          return confStatus;
        } catch (error) {
          commit(GET_CONFIRMATION_STATUS.FAILED, error);
          console.error(error);
          return Promise.reject(error);
        }
      },
      async startOrderReservation(
        { commit },
        {
          orderId,
          onlinePayment
        }: {
            orderId: string;
            onlinePayment: boolean
        }
      ) {
        try {
          const reservation = await api.startOrderReservation(
            orderId,
            onlinePayment
          );
          commit(START_ORDER_RESERVATION.SUCCEEDED, reservation);
          return reservation;
        } catch (error) {
          commit(START_ORDER_RESERVATION.FAILED, error);
          return Promise.reject(error);
        }
      },
      async getReservationStatus({ commit }, orderId): Promise<any> {
        try {
          const reservationStatus = await api.getReservationStatus(orderId);
          commit(GET_RESERVATION_STATUS.SUCCEEDED, reservationStatus);
          return reservationStatus;
        } catch (error) {
          commit(GET_RESERVATION_STATUS.FAILED, error);
          console.error(error);
        }
      },
      async findCountry(
        { commit }: ActionContext<IPackageOrderState, RootState>,
        request: {
            term: string;
            locale: string
        }
      ) {
        commit(FIND_COUNTRY.INITIAL);
        try {
          commit(FIND_COUNTRY.SUCCEEDED, await api.findCountry(request));
        } catch (error) {
          commit(FIND_COUNTRY.FAILED, error);
        }
      },
      async getProductPrice({ commit }, {
        searchId,
        productId
      }) {
        commit(GET_PRODUCT_PRICE.INITIAL);
        try {
          commit(
            GET_PRODUCT_PRICE.SUCCEEDED,
            await api.getAgencyPrices(searchId, productId)
          );
        } catch (error) {
          commit(GET_PRODUCT_PRICE.FAILED, error);
        }
      },
      async getOrderItems(
        { commit },
        {
          orderId,
          queryType
        }: {
            orderId: string;
            queryType?: QueryTypes
        }
      ) {
        try {
          const fn =
                queryType === QueryTypes.hotel
                  ? api.getPackageOrderItemsForHotels
                  : api.getPackageOrderItems;
          commit(GET_ORDER_ITEMS.SUCCEEDED, await fn(orderId));
          // commit(GET_ORDER_ITEMS.SUCCEEDED, await api.getPackageOrderItems(orderId))
        } catch (error) {
          commit(GET_ORDER_ITEMS.FAILED);
        }
      },

      async setMarkupToOrder(
        { commit },
        {
          orderId,
          orderItemId,
          markup
        }: {
            orderId: string;
            orderItemId: string;
            markup: number;
        }
      ) {
        commit(SET_MARKUP_TO_ORDER.INITIAL);
        try {
          await api.setMarkupToOrder({
            orderId,
            orderItemId,
            markup
          });
          commit(SET_MARKUP_TO_ORDER.SUCCEEDED);
          return Promise.resolve();
        } catch (e) {
          commit(SET_MARKUP_TO_ORDER.FAILED, e);
          return Promise.reject();
        }
      },

      async setMarkupPrice({ commit }, markupPrice: string) {
        commit(SET_MARKUP_PRICE, markupPrice);
      },
      async getOrder({ commit }, { orderId }: {
        orderId: string
    }) {
        try {
          const response: AxiosResponse<TravelOrderResource> = await api.getOrder({
            orderId
          });
          commit(SET_ORDER.SUCCEEDED, response.data);
        } catch (error) {
          commit(SET_ORDER.FAILED, error);
        }
      }
    };

const mutations: MutationTree<IPackageOrderState> = {
  [SET_IS_LAST_STEP_BOOKING](
    state: IPackageOrderState,
    status: boolean = null
  ) {
    state.isLastStepBooking =
            status !== null ? status : !state.isLastStepBooking;
  },
  [GET_PAX_TYPE.INITIAL](state, payload: IPaxTypeInformation) {
    state.customerDOBrule = payload;
  },
  [GET_PAX_TYPE.SUCCEEDED](state, payload: IPaxTypeInformation) {
    state.customerDOBrule = payload;
  },
  [GET_PAX_TYPE.FAILED](state, error) {
    console.error(error);
  },
  [GET_PAX_TYPE_BY_COMPLECT_ID.INITIAL](state) {
  },
  [GET_PAX_TYPE_BY_COMPLECT_ID.SUCCEEDED](state, payload: IPaxTypeInformation[]) {
    state.customerDOBrule = payload[0];
    state.customerDOBrules = payload;
  },
  [GET_PAX_TYPE_BY_COMPLECT_ID.FAILED](state, error) {
    console.error(error);
  },
  [ADD_ADDITIONAL_PAYMENTS_TO_CUSTOMER.SUCCEEDED](state, response) {
    // console.log(state, response)
  },
  [ADD_ADDITIONAL_PAYMENTS_TO_CUSTOMER.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "add-baggage-to-customer"
    };
  },
  [GET_CONFIRMATION_STATUS.INITIAL](state) {
    state.conformationStatusPending = true;
    state.conformationError = null;
  },
  [GET_CONFIRMATION_STATUS.SUCCEEDED](
    state,
    result: {
            status: "OK" | "AWAIT" | "ERROR"
        }
  ) {
    state.conformationStatus = result.status;
    state.conformationStatusPending = false;
    state.conformationError = null;
  },
  [GET_CONFIRMATION_STATUS.FAILED](state, error) {
    console.error(error);
    state.conformationError = error;
    state.conformationStatus = null;
    state.conformationStatusPending = false;
  },
  [GET_AGENCY_LOGO.SUCCEEDED](state, response) {
    state.agencyLogoPending = false;
    state.agencyLogoUrl = window.URL.createObjectURL(response.data);
  },
  [GET_AGENCY_LOGO.FAILED](state, error) {
    state.agencyLogoUrl = "error";
    state.agencyLogoPending = false;
  },
  [SET_ORDER_ITEM](state, orderItem) {
    state.bookedProduct = orderItem.bookedProduct;
    state.bookedProductPrice = orderItem.bookedProductPrice;
  },
  [GET_ORDER_ITEMS.SUCCEEDED](state, items) {
    state.orderItems = items.data;
  },
  [GET_ORDER_ITEMS.FAILED](state, error) {
    state.error = {
      code: error?.status || 404,
      type: "get-order-items"
    };
  },
  [INPUT_OWNER](state, ownerData: ICustomer) {
    state.owner[0] = ownerData;
  },
  [SET_ORDER_PENDING](state, isPending) {
    state.isOrderPending = isPending;
  },
  [SET_ORDER_ERROR](state, error) {
    state.error = error;
  },
  [INPUT_ALL_CUSTOMERS](state: IPackageOrderState, customers: ICustomer[]) {
    state.customers = _.cloneDeep(customers);
  },
  [INPUT_CUSTOMER](state, {
    customerData,
    customerIndex
  }) {
    customerIndex === -1
      ? state.customers.push(customerData)
      : Vue.set(state.customers, customerIndex, customerData);
  },
  [INPUT_CUSTOMER_INDEXES](state, {
    roomIndex,
    paxIndex
  }) {
    if (paxIndex > -1) {
      const customer = _.cloneDeep(state.customers[paxIndex]);
      if (customer) {
        customer.paxIndex = paxIndex;
        customer.roomIndex = roomIndex;
        state.customers[paxIndex] = customer
      }
    }
  },
  [INPUT_ASSISTANCE_PENDING](state, isPending: boolean) {
    state.isAssistancePending = isPending;
  },
  [FIND_COUNTRY.INITIAL](state) {
    state.isCountryPending = true;
  },
  [FIND_COUNTRY.SUCCEEDED](state, response: any) {
    const countries: ILocation[] = response.data;
    state.countries = countries;
    state.isCountryPending = false;
  },
  [FIND_COUNTRY.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "find-country"
    };
    state.isCountryPending = false;
  },
  [GET_DICTIONARY_BOARDING.SUCCEEDED](state, response: any) {
    state.dictionaryBoarding = response.data;
  },
  [GET_DICTIONARY_BOARDING.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "dictionary-boarding"
    };
    state.isCountryPending = false;
  },
  [BEGIN_ORDER.SUCCEEDED](state, data: IBeginOrderResponse) {
    state.customers = [];
    state.orderId = data.entityId;
  },
  [SET_ORDER_ID](state, orderId: string) {
    state.orderId = orderId;
  },
  [BEGIN_ORDER.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "order-begin"
    };
    state.isOrderPending = false;
  },
  [PRODUCT_TO_ORDER.SUCCEEDED](state, {
    response,
    complectIds
  }) {
    const itemIds = response.data.map((data: any) => data.entityId);
    complectIds.forEach((complectId: string, index: number) => {
      if (state.itemIds[complectId]) {
        state.itemIds[complectId].push(itemIds[index]);
      } else {
        state.itemIds[complectId] = [itemIds[index]];
      }
    });
  },
  [CLEAR_ITEM_IDS](state) {
    state.itemIds = {};
  },
  [SET_ITEM_IDS](state, itemIds) {
    state.itemIds = itemIds;
  },
  [PRODUCT_TO_ORDER.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "product-to-order"
    };
    state.isOrderPending = false;
  },
  [REGISTRATION_OWNER.SUCCEEDED](state, result) {
    const resultOwnerData: ICustomer = result.data;
    state.owner[0] = {
      ...state.owner[0],
      customerId: resultOwnerData.customerId
    };
  },
  [REGISTRATION_OWNER.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "registration-owner"
    };
    state.isOrderPending = false;
  },
  [REGISTRATION_NEW_CUSTOMER.SUCCEEDED](
    state,
    {
      index,
      result,
      isOwner
    }: {
            complectId: string;
            index: number;
            result: any,
            isOwner: boolean;
        }
  ) {
    const resultCustomerData: ICustomer = result.data;

    if (isOwner) {
      const customer = state.owner[index] ?? resultCustomerData;
      state.owner[index] = {
        ...customer,
        customerId: resultCustomerData.customerId
      };
    } else {
      const customer = state.customers[index] ?? resultCustomerData;
      state.customers[index] = {
        ...customer,
        customerId: resultCustomerData.customerId
      };
    }
  },
  [REGISTRATION_NEW_CUSTOMER.FAILED](state, error) {
    state.error = error;
    state.isOrderPending = false;
  },
  [SET_CONTACT_PERSON.SUCCEEDED](state) {
    //
  },
  [SET_CONTACT_PERSON.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "set-contact-person"
    };
  },
  [ADD_CUSTOMER_TO_ORDER_ITEM.SUCCEEDED](state, response) {
    //
  },
  [ADD_CUSTOMER_TO_ORDER_ITEM.FAILED](state, error) {
    state.error = {
      code: error?.status,
      type: "add-customer-to-order-item"
    };
  },
  [GET_ORDER_PRERESERVATION_STATUS.SUCCEEDED](state, response) {
    state.orderPrereservationStatus = response.data.status;
  },
  [GET_ORDER_PRERESERVATION_STATUS.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "get-order-prereservation"
    };
  },
  [START_ORDER_RESERVATION.SUCCEEDED](state, response) {
    state.orderNumber = response.data.number;
  },
  [START_ORDER_RESERVATION.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "start-order-reservation"
    };
    state.isOrderPending = false;
  },
  [GET_RESERVATION_STATUS.SUCCEEDED](state, result: any) {
    const status: string = result.data.status;
    state.orderStatus = status;
    state.isOrderPending = false;
  },
  [GET_RESERVATION_STATUS.FAILED](state, error) {
    state.error = {
      code: error.status,
      type: "error in reservation status"
    };
  },
  [SET_ADDITIONAL_PAYMENTS](state, additional: TRoomsAdditions[]) {
    state.additions = additional;
  },
  [GENERATE_ADDITIONAL_PAYMENTS](state, generatedAdditions: TRoomsAdditions[]) {
    state.additions = generatedAdditions;
  },
  [SET_MARKUP_TO_ORDER.INITIAL](state, error) {
    console.log("[SET_MARKUP_TO_ORDER.INITIAL]");
  },
  [SET_MARKUP_TO_ORDER.SUCCEEDED](state, error) {
    console.log("[SET_MARKUP_TO_ORDER.SUCCEEDED]");
  },
  [SET_MARKUP_TO_ORDER.FAILED](state, error) {
    console.error(error);
  },
  [SET_MARKUP_PRICE](state, markupPrice: number) {
    state.markupPrice = markupPrice ? Number(markupPrice) : null;
  },
  [SET_ORDER.SUCCEEDED](state, order: TravelOrderResource) {
    state.order = order;
  },
  [SET_ORDER.FAILED](state, error) {
    console.error(error);
  }
};

export const productOrderStore: Module<IPackageOrderState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};

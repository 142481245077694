import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

import actionHelper from "utils/actionHelper";

import cloneDeep from "lodash/cloneDeep";
import chunk from "lodash/chunk";
import flatten from "lodash/flatten";
import api from "./fly-result.api";
import {
  IAgencyLogos,
  IFilter,
  IFlyResultState,
  IResultProduct,
  IResultProductPrice,
  IRule
} from "./types/fly-result.types";
import NotificationName from "../notification/notification.name";
import { AgencyPriceResource, FlightSearchItemResource } from "tl-api-doc/typescript-generator";
import { RootState } from "../../applications/desktop/store";
import { INotification } from "../notification/data/notification.types";

// TODO: realize helper-module for action-mutation
const GET_FLY_SEARCH_RESULT = actionHelper("GET_FLY_SEARCH_RESULT");
const GET_FLY_SEARCH_RESULT_PRICE = actionHelper("GET_FLY_SEARCH_RESULT_PRICE");
const GET_FLY_SEARCH_PRODUCT_RULES = actionHelper(
  "GET_FLY_SEARCH_PRODUCT_RULES"
);
const GET_AGENCY_LOGO = actionHelper("GET_AGENCY_LOGO");

const GET_SORTED_PRICES = actionHelper("GET_SORTED_PRICES");
const SET_FARE_FAMILIES_PRODUCTS = actionHelper("SET_FARE_FAMILIES_PRODUCTS");

const UPDATE_FILTER = "UPDATE_FILTER";
const UPDATE_FAKE_FILTER = "UPDATE_FAKE_FILTER";
const RESET_STORE = "RESET_STORE";

const RESET_FILTER = "RESET_FILTER";
const SET_NOTIFICATION = "SET_NOTIFICATION";

const CLEAR_RESULT = "CLEAR_RESULT";
const SET_SELECTED_FARE_FAMILY = "SET_SELECTED_FARE_FAMILIES";
const SET_TOOLTIP_PRODUCT_ID = "SET_TOOLTIP_PRODUCT_ID";

const namespaced = true;

const state: IFlyResultState = {
  beeId: null,
  beeTimer: null,
  beeType: null,
  isFareFamiliesProductsPending: false,
  isFareFamiliesProductsError: null,
  fareFamiliesPricesData: null,
  fareFamiliesProducts: null,
  fakeFilter: {
    airlines: [],
    flightTypes: []
  },
  filter: {
    airlines: [],
    connectionTime: {
      max: "PT200H",
      min: "PT0S"
    },
    groupByAirline: false,
    inArrival: {
      max: "23:59",
      min: "00:00"
    },
    inDeparture: {
      max: "23:59",
      min: "00:00"
    },
    inFlightTime: {
      max: "PT200H",
      min: "PT0S"
    },
    outArrival: {
      max: "23:59",
      min: "00:00"
    },
    outDeparture: {
      max: "23:59",
      min: "00:00"
    },
    outFlightTime: {
      max: "PT200H",
      min: "PT0S"
    },
    page: 0,
    price: {
      max: 99999,
      min: 0
    },
    size: 40,
    sort: [
      {
        PRICE: "ASC"
      }
    ],
    flightTypes: [],
    stops: {
      max: 2,
      min: 0
    },
    departureTime: {},
    arrivalTime: {},
    flightDuration: {}
  },
  filterDefault: {
    airlines: [],
    connectionTime: {
      max: "PT8760H",
      min: "PT0S"
    },
    flightPenaltyTypes: null,
    inArrival: {
      max: "23:59",
      min: "00:00"
    },
    inDeparture: {
      max: "23:59",
      min: "00:00"
    },
    inFlightTime: {
      max: "PT8760H",
      min: "PT0S"
    },
    outArrival: {
      max: "23:59",
      min: "00:00"
    },
    outDeparture: {
      max: "23:59",
      min: "00:00"
    },
    outFlightTime: {
      max: "PT8760H",
      min: "PT0S"
    },
    page: 0,
    price: {
      max: 99999,
      min: 0
    },
    size: 40,
    sort: [
      {
        PRICE: "ASC"
      }
    ],
    flightTypes: [],
    stops: {
      max: 2,
      min: 0
    },
    departureTime: {},
    arrivalTime: {},
    flightDuration: {}
  },
  isComparePending: false,
  isFilterPending: false,
  isLoadingPending: false,
  isPricePending: false,
  resultAgencyLogos: {},
  resultProductRules: {},
  resultProducts: null,
  resultProductsPrices: null,
  resultStatus: "",
  selectedFareFamily: null,
  tooltipProductId: null
};

const getters: GetterTree<IFlyResultState, RootState> = {
  beeTimer: (state: IFlyResultState) => state.beeTimer,
  beeType: (state: IFlyResultState) => state.beeType,
  beeId: (state: IFlyResultState) => state.beeId,
  isFareFamiliesProductsPending: (state: IFlyResultState) => state.isFareFamiliesProductsPending,
  isFareFamiliesProductsError: (state: IFlyResultState) => state.isFareFamiliesProductsError,
  fareFamiliesPricesData: (state: IFlyResultState) => state.fareFamiliesPricesData,
  fareFamiliesProducts: (state: IFlyResultState) => state.fareFamiliesProducts,
  fakeFilter: (state: IFlyResultState) => state.fakeFilter,
  filter: (state: IFlyResultState) => state.filter,
  filterDefault: (state: IFlyResultState) => state.filterDefault,
  flightSearchItemResources: (state: IFlyResultState) =>
    state.resultProducts && state.resultProducts.content
      ? state.resultProducts
      : [],
  isComparePending: (state: IFlyResultState) => state.isComparePending,
  isFilterPending: (state: IFlyResultState) => state.isFilterPending,
  isLoadingPending: (state: IFlyResultState) => state.isLoadingPending,
  isPricePending: (state: IFlyResultState) => state.isPricePending,
  isSearchEnd: (state: IFlyResultState) =>
    state.resultProducts &&
        state.resultProducts.page &&
        state.resultProducts.page.query &&
        state.resultProducts.page.query.searchStatus === "DEALS_POST_PROCESSED",
  isSupplierResponded: (state: IFlyResultState) =>
    state.resultProducts &&
        state.resultProducts.page &&
        state.resultProducts.page.query &&
        state.resultProducts.page.query.searchStatus === "SUPPLIERS_RESPONDED",
  resultAgencyLogos: (state: IFlyResultState) => state.resultAgencyLogos,
  resultProductRules: (state: IFlyResultState) => state.resultProductRules,
  resultProducts: (state: IFlyResultState) => state.resultProducts,
  resultProductsPrices: (state: IFlyResultState) => state.resultProductsPrices,
  resultStatus: (state: IFlyResultState) => state.resultStatus,
  selectedFareFamily: (state: IFlyResultState) => state.selectedFareFamily,
  tooltipProductId: (state: IFlyResultState) => state.tooltipProductId
};

const actions: ActionTree<IFlyResultState, RootState> = {
  async endTimer({ state, commit }) {
    clearInterval(state.beeId);
    commit("END_TIME");
  },
  async startTimer({ commit }, { type }) {
    commit("START_TIME", { type });
  },
  async resetFlyFilters({ commit }) {
    return new Promise<void>((resolve, reject) => {
      try {
        commit(RESET_FILTER);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
  async sortPrices(
    { commit },
    {
      searchId,
      productId,
      sort
    }: {
            searchId: string
            productId: string
            sort: string
        }
  ) {
    try {
      commit(GET_SORTED_PRICES.INITIAL);
      commit(
        GET_SORTED_PRICES.SUCCEEDED,
        await api.getSearchResultsPrice(searchId, productId, sort)
      );
    } catch (e) {
      commit(GET_SORTED_PRICES.FAILED, e);
    }
  },
  async getFareFamilies({ commit }, {
    productId,
    searchId
  }: {
        productId: string,
        searchId: string
    }): Promise<FlightSearchItemResource[]> {
    commit(SET_FARE_FAMILIES_PRODUCTS.INITIAL);
    try {
      const productsData =
                (await api.getFareFamilies({ productId })).data;

      const productIds = productsData.map(product => product.productId);

      const chunkedProductIds = Array.isArray(productIds) ? chunk(productIds, 20) : [[productId]];

      const pricesData: IResultProductPrice[][] = await Promise.all(chunkedProductIds.map(async(productIdsPart) => {
        return (await api.getSearchResultsPrice(
          searchId,
          productIdsPart
        )).data;
      }
      )
      );

      commit(SET_FARE_FAMILIES_PRODUCTS.SUCCEEDED, {
        productsData,
        pricesData: pricesData.flat()
      });
      return productsData;
    } catch (error) {
      commit(SET_FARE_FAMILIES_PRODUCTS.FAILED, error);
      return Promise.reject(error);
    }
  },

  async getProductFromTab({ commit }, {
    productId,
    searchId,
    locale
  }: {
        productId: string,
        searchId: string,
        locale: string
    }): Promise<{
        product: IResultProduct,
        price: IResultProductPrice[]
    }> {
    // тут ждем только 1 продукт
    const products: IResultProduct[] = (await api.getSearchResults(
      searchId,
      { productIds: [productId] },
      locale
    )).data.content as IResultProduct[];

    const product = products.find(product => product.productId === productId);

    const price: IResultProductPrice[] = (await api.getSearchResultsPrice(
      searchId,
      product.productId
    )).data;

    return {
      product,
      price
    };
  },

  async getSearchResults(
    {
      commit,
      state,
      getters,
      rootGetters
    },
    {
      searchId,
      filter,
      newSearch,
      filterChanged,
      locale,
      options
    }: {
            searchId: string
            filter: IFilter
            newSearch: boolean
            filterChanged: boolean
            locale: string
            options: boolean
        }
  ) {
    if (newSearch) {
      commit(GET_FLY_SEARCH_RESULT.INITIAL);
    }
    if (filterChanged) {
      state.isFilterPending = true;
    } else {
      state.isLoadingPending = true;
    }

    try {
      const result = await api.getSearchResults(searchId, options
        ? {
          ...filter,
          page: 0,
          airlines: filter.airlines.length === 0
            ? state.fakeFilter.airlines
            : state.fakeFilter.airlines.filter(fkAir => filter.airlines.includes(fkAir)),
          flightTypes: filter.flightTypes.length === 0
            ? state.fakeFilter.flightTypes
            : state.fakeFilter.flightTypes.filter(fkAir => filter.flightTypes.includes(fkAir)),
          size: 500
        }
        : filter, locale);
      commit(GET_FLY_SEARCH_RESULT.SUCCEEDED, {
        filterChanged,
        isSearchEnd: getters.isSearchEnd,
        newSearch,
        options,
        result
      });
      if (!newSearch && filterChanged && !result.data.content.length) {
        // empty products
        if (rootGetters[`${NotificationName}/notification`]?.message !== "selected-types-not-found") {
          const notification: INotification = {
            icon: "bee_not_found",
            message: "empty-products",
            type: "info"
          };
          commit(`${NotificationName}/${SET_NOTIFICATION}`, notification, { root: true });
        }
      }
      return result;
    } catch (e) {
      console.error("1", e);
      // const notification: INotification = {
      //   message: "products",
      //   type: "error"
      // };
      // commit(`${NotificationName}/${SET_NOTIFICATION}`, notification, { root: true });
      commit(GET_FLY_SEARCH_RESULT.FAILED, e);
      return Promise.reject(e);
    }
  },
  initialProductRules({ commit }, productId: string) {
    commit(GET_FLY_SEARCH_PRODUCT_RULES.RESET, productId);
  },
  async getProductRules({
    commit,
    state
  }, productId: string) {
    if (!state.resultProductRules || !state.resultProductRules[productId]) {
      commit(GET_FLY_SEARCH_PRODUCT_RULES.INITIAL, productId);
    }

    try {
      commit(GET_FLY_SEARCH_PRODUCT_RULES.SUCCEEDED, {
        productId,
        response: await api.getProductRules(productId)
      });
    } catch (e) {
      const notification: INotification = {
        icon: "sad_bee",
        message: "rules",
        type: "error"
      };
      commit(`${NotificationName}/${SET_NOTIFICATION}`, notification, { root: true });
      console.error("rules", e);
    }
  },
  async getSearchResultsPrice(
    { commit },
    {
      searchId,
      productId,
      filterChanged
    }: {
            searchId: string
            productId: string | string[]
            filterChanged: boolean
            caller: string
        }
  ) {
    commit(GET_FLY_SEARCH_RESULT_PRICE.INITIAL, filterChanged);
    try {
      commit(GET_FLY_SEARCH_RESULT_PRICE.SUCCEEDED, await api.getSearchResultsPrice(searchId, productId));
    } catch (e) {
      console.error("2", e);
      // const notification = {
      //   message: "prices",
      //   type: "error"
      // };
      // commit(`${NotificationName}/${SET_NOTIFICATION}`, notification, { root: true });
      commit(GET_FLY_SEARCH_RESULT_PRICE.FAILED, e);
    }
  },
  async getSearchResultsChunkedPrice(
    { commit },
    {
      searchId,
      productId,
      filterChanged
    }: {
            searchId: string
            productId: string | string[]
            filterChanged: boolean
            caller: string
        }
  ) {
    commit(GET_FLY_SEARCH_RESULT_PRICE.INITIAL, filterChanged);
    try {
      const chunkedProductIds = Array.isArray(productId) ? chunk(productId, 20) : [[productId]];
      const promisePreDataArr = chunkedProductIds.map(stringArr => api.getSearchResultsPrice(
        searchId,
        stringArr
      ));
      const preDataArr = await Promise.all(promisePreDataArr);
      const dataArr = preDataArr.map(axiosResp => axiosResp.data);
      const data = flatten(dataArr);
      commit(
        GET_FLY_SEARCH_RESULT_PRICE.SUCCEEDED,
        { data }
      );
    } catch (e) {
      console.error("3", e);
      // const notification = {
      //   message: "prices",
      //   type: "error"
      // };
      // commit(`${NotificationName}/${SET_NOTIFICATION}`, notification, { root: true });
      commit(GET_FLY_SEARCH_RESULT_PRICE.FAILED, e);
    }
  },
  getAgenciesLogo({ commit }, params: IAgencyLogos) {
    params.agencyIds.forEach(async agencyId => {
      try {
        commit(GET_AGENCY_LOGO.SUCCEEDED, {
          agencyId,
          response: await api.getAgencyLogo(agencyId, params.size)
        });
      } catch (e) {
        commit(GET_AGENCY_LOGO.FAILED, e);
      }
    });
  },
  setSelectedFareFamily({ commit }, fareFamily: FlightSearchItemResource) {
    commit(SET_SELECTED_FARE_FAMILY, fareFamily);
  },
  updateFilter({ commit }, filter: IFilter) {
    commit(UPDATE_FILTER, filter);
  },
  updateFakeFilter({ commit }, filter: {
        airlines: string[]
    }) {
    commit(UPDATE_FAKE_FILTER, filter);
  },
  setTooltipProductId({ commit }, tooltipProductId) {
    commit("SET_TOOLTIP_PRODUCT_ID", tooltipProductId);
  }
};

const mutations: MutationTree<IFlyResultState> = {
  "START_TIME"(state, { type }) {
    state.beeType = type;
    state.beeTimer = 0;
    if (state.beeId) clearInterval(state.beeId);
    state.beeId = setInterval(() => {
      state.beeTimer++;
    }, 1000);
  },
  "END_TIME"(state) {
    state.beeType = null;
    state.beeTimer = 0;
    state.beeId = null;
  },
  [SET_FARE_FAMILIES_PRODUCTS.INITIAL](state) {
    state.isFareFamiliesProductsPending = true;
    state.isFareFamiliesProductsError = null;
    state.fareFamiliesProducts = null;
    state.fareFamiliesPricesData = null;
  },
  [SET_FARE_FAMILIES_PRODUCTS.SUCCEEDED](state, {
    productsData,
    pricesData
  }: {
        productsData: FlightSearchItemResource[],
        pricesData: AgencyPriceResource[]
    }) {
    state.isFareFamiliesProductsPending = false;
    state.fareFamiliesProducts = productsData;
    state.fareFamiliesPricesData = pricesData;
  },
  [SET_FARE_FAMILIES_PRODUCTS.FAILED](state, error) {
    state.isFareFamiliesProductsPending = false;
    state.isFareFamiliesProductsError = error;
  },
  [GET_SORTED_PRICES.INITIAL](state) {
    state.isComparePending = true;
  },
  [GET_SORTED_PRICES.SUCCEEDED](state, response) {
    const productId: string = response.data[0].productId;
    const targetIndexes: number[] = [];
    state.resultProductsPrices.forEach((item, index) => {
      if (item.productId === productId) {
        targetIndexes.push(index);
      }
    });

    targetIndexes.forEach((item, index) =>
      state.resultProductsPrices.splice(item, 1, response.data[index])
    );

    state.isComparePending = false;
  },
  [GET_SORTED_PRICES.FAILED](state, error) {
    state.isComparePending = false;
  },

  [UPDATE_FILTER](state, filter: IFilter) {
    state.filter = { ...filter };
  },
  [UPDATE_FAKE_FILTER](state, filter: {
        airlines: string[],
        flightTypes: string[]
    }) {
    state.fakeFilter = filter;
  },
  [RESET_FILTER](state) {
    state.filter = { ...state.filterDefault };
    state.filter = cloneDeep(state.filterDefault);
  },
  [RESET_STORE](state) {
    state.filter = cloneDeep(state.filterDefault);
    state.resultProducts = null;
    state.resultProductRules = {};
    state.resultProductsPrices = null;
    state.isFilterPending = false;
    state.isLoadingPending = false;
    state.isPricePending = false;
  },
  [GET_FLY_SEARCH_RESULT.INITIAL](state) {
    state.resultProducts = null;
    state.isPricePending = true;
    state.isLoadingPending = true;
  },
  [GET_FLY_SEARCH_RESULT.SUCCEEDED](
    state,
    {
      result,
      newSearch,
      filterChanged,
      isSearchEnd,
      options
    }
  ) {
    if (options) {
      state.resultProducts.content.forEach(item => {
        const itemAllOptions: IResultProduct[] = (result.data.content as IResultProduct[]).filter(contentItem => contentItem.codeShareCarrier.code ===
                    item.codeShareCarrier.code && item.productId !== contentItem.productId);
        item.options = itemAllOptions;
      });
      state.isLoadingPending = false;
      state.isFilterPending = false;

      return;
    }

    if (newSearch) {
      // new search or restart after F5 (will execute only 1 time per search)
      state.resultProducts = result.data;
      state.resultProducts.content = state.resultProducts.content.map(el => ({
        ...el,
        options: el.options || []
      }));
    } else {
      if (filterChanged) {
        // on apply new filter
        state.resultProducts = result.data;
        state.resultProducts.content = state.resultProducts.content.map(el => ({
          ...el,
          options: el.options || []
        }));
      } else {
        const oldContent: IResultProduct[] = state.resultProducts.content;
        if (isSearchEnd) {
          // load more
          state.resultProducts = {
            ...result.data,
            content: oldContent.concat(result.data.content).map(el => ({
              ...el,
              options: el.options || []
            }))
          };
        } else {
          // polling
          let duplicates = 0;
          result.data.content.forEach((item: IResultProduct) => {
            if (oldContent.find(
              (product: IResultProduct) => product.productId === item.productId
            )) {
              duplicates += 1;
            }
          });

          state.resultProducts =
                        duplicates < result.data.content.length
                          ? { ...result.data } // response with duplicates and new items
                          : {
                            ...result.data,
                            content: oldContent
                          }; // response only with duplicates
        }
      }
    }

    state.isLoadingPending = false;
    state.isFilterPending = false;
    state.resultStatus = result.data.page.query.searchStatus;
  },
  [GET_FLY_SEARCH_RESULT.FAILED](state, error) {
    state.isLoadingPending = false;
  },
  [GET_FLY_SEARCH_RESULT_PRICE.INITIAL](state, filterChanged) {
    if (filterChanged) {
      state.resultProductsPrices = null;
    }
    state.isPricePending = true;
  },
  [GET_FLY_SEARCH_RESULT_PRICE.SUCCEEDED](state, prices) {
    if (prices.data.length > 0) {
      state.isPricePending = false;
    }
    if (state.resultProductsPrices && state.resultProductsPrices.length) {
      state.resultProductsPrices = state.resultProductsPrices.concat(
        prices.data
      );
    } else {
      state.resultProductsPrices = prices.data;
    }
  },
  [GET_FLY_SEARCH_RESULT_PRICE.FAILED](state, error) {
    state.isPricePending = false;
  },
  [GET_AGENCY_LOGO.INITIAL](state) {
    // console.log()
  },
  [GET_AGENCY_LOGO.SUCCEEDED](state, {
    response,
    agencyId
  }) {
    state.resultAgencyLogos[agencyId] = window.URL.createObjectURL(
      response.data
    );
  },
  [GET_AGENCY_LOGO.FAILED](state, error) {
    // console.error(error)
  },
  [GET_FLY_SEARCH_PRODUCT_RULES.INITIAL](state, productId) {
    state.resultProductRules[productId] = {
      rules: [],
      status: "AWAIT"
    };
  },
  [GET_FLY_SEARCH_PRODUCT_RULES.RESET](state, productId) {
    state.resultProductRules = {};
  },
  [GET_FLY_SEARCH_PRODUCT_RULES.SUCCEEDED](state, {
    productId,
    response
  }) {
    const status = response.data.status;
    const rules = response.data.fareRules || [];
    if (rules.length) {
      rules.map((rule: IRule) => {
        if (rule.freeText && rule.freeText.length) {
          const newRules = (rule.freeText as string)
            .split("\n")
            .filter((freeText: string) => freeText.trim().length)
            .map((freeText: string) => freeText.replace(/\s/g, "&nbsp;"));
          rule.freeText = newRules;
        }
        return rule;
      });
    }
    state.resultProductRules[productId] = {
      rules,
      status
    };
  },
  [SET_SELECTED_FARE_FAMILY](state, fareFamily: FlightSearchItemResource) {
    if (fareFamily && state.selectedFareFamily?.productId !== fareFamily.productId) {
      state.selectedFareFamily = fareFamily;
    } else {
      state.selectedFareFamily = null;
    }  
  },
  [SET_TOOLTIP_PRODUCT_ID](state, tooltipProductId) {
    state.tooltipProductId = tooltipProductId;
  }
};

export const flyResultStore: Module<IFlyResultState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};

import { Store, StoreOptions } from "vuex";

import FLY_LOCATION from "./fly-location.name";
import { flyLocationStore } from "./fly-location.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(FLY_LOCATION, flyLocationStore);
};

import { Store, StoreOptions } from "vuex";

import PRODUCT_SEARCH_MODULE from "modules/product-search/product-search.name";
import { productSearchStore } from "modules/product-search/product-search.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(PRODUCT_SEARCH_MODULE, productSearchStore);
};
